/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Utilities from "../../lib/Utilities";

const util = new Utilities();

const user_default_image = "https://www.gbts.co.kr/images/user_pic-50x50.png";

export default function LogiTalkChatList(props) {
  const {
    loginInfo,
    chatRoomList,
    openNewsChatRoom,
    newsChatList,
    alertDisabledList,
    addActiveChRoom,
  } = props;
  const [isSearch, setIsSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const searchBox = useRef();

  useEffect(() => {
    if (searchBox.current) searchBox.current.value = "";
    setSearchText("");
  }, [isSearch]);

  const ChatRoomItemRender = useCallback((props) => {
    return <ChatRoomItem {...props} />;
  }, []);

  const ChatNewsItemRender = useCallback((props) => {
    return <ChatNewsItem {...props} />;
  }, []);

  const mappedData = useMemo(() => {
    return util.arraySort([...chatRoomList, ...newsChatList], "LastTime", true);
  }, [chatRoomList, newsChatList]);

  return (
    <div className="white_bg p-3 w-100 h-100 overflow_scroll scroll_hidden">
      <div className="py-2 d-flex flex-row justify-content-between align-items-center">
        <h4 className="m-0 text-info">대화방</h4>
        <span
          className="px-1 cursor_pointer"
          onClick={() => {
            setIsSearch(!isSearch);
          }}
        >
          <i
            className={
              !isSearch ? "fa-solid fa-magnifying-glass" : "fa-solid fa-xmark"
            }
          />
        </span>
      </div>
      {isSearch && (
        <div className="pb-3">
          <input
            ref={searchBox}
            type="text"
            className="form-control p-1 bg-light border-0 rounded"
            placeholder="검색어를 입력해주세요"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
      )}

      <div>
        {mappedData.map((item, index) => {
          if (item.hasOwnProperty("cnmMainKey")) {
            return (
              <ChatNewsItemRender
                key={item.cnmMainKey}
                item={item}
                searchText={searchText}
                openNewsChatRoom={openNewsChatRoom}
                alertDisabledList={alertDisabledList}
              />
            );
          }

          return (
            <ChatRoomItemRender
              key={item.crmMainKey}
              chat={item}
              loginInfo={loginInfo}
              searchText={searchText}
              addActiveChRoom={addActiveChRoom}
              alertDisabledList={alertDisabledList}
            />
          );
        })}
      </div>
    </div>
  );
}
const ChatRoomItem = React.memo((props) => {
  const { chat, loginInfo, searchText, addActiveChRoom, alertDisabledList } =
    props;

  let msg = "";
  let chatUser = {};
  let userList = [];

  if (chat.hasOwnProperty("crmMainKey")) {
    for (let i = 0; i < chat.chatUserList.length; i++) {
      let user = chat.chatUserList[i];
      if (
        user.cruUserID === loginInfo.user_id &&
        user.cruUserSite === loginInfo.comp_cd
      ) {
        chatUser = user;
      } else {
        userList.push(user);
        if (user.cruUserID === chat.crmCreator) {
          msg = `${
            user.UserNICK ? user.UserNICK : user.UserName
          } 님이 대화를 요청하였습니다.`;
        }
      }
    }

    userList.push(chatUser);
    if (userList.length > 4) {
      userList = userList.slice(0, 4);
    }

    if (msg.indexOf("|¿|") > -1) {
      let msgSplit = msg.split("|¿|");
      msgSplit.splice(0, 3);
      msg = msgSplit.join("");
    }
    if (chat.crmLastMSG.indexOf("|¿|") > -1) {
      let msgSplit = chat.crmLastMSG.split("|¿|");
      msgSplit.splice(0, 3);
      chat.crmLastMSG = msgSplit.join("");
    }

    let isDisplay = true;
    if (searchText !== "" && chat.chatRoomTitle.indexOf(searchText) === -1) {
      isDisplay = false;
    }

    return (
      <div
        className={`${
          isDisplay ? "d-flex" : "d-none"
        }  flex-row justify-content-start align-items-center cursor_pointer position-relative p-2 mb-2 rounded-lg bg-light`}
        onClick={() => addActiveChRoom(chat)}
      >
        {!Boolean(chatUser.lastReadIDX) && !Boolean(chatUser.unreadCount) && (
          <div
            className="d-inline-block rounded-pill position-absolute px-2 d-flex flex-row justify-content-center align-items-center font-weight-bold text-white"
            style={{
              height: 20,
              right: "0.5rem",
              top: "0.5rem",
              background: "#3B44AC",
              fontSize: "0.8em",
            }}
          >
            NEW
          </div>
        )}

        {chatUser.unreadCount > 0 && (
          <div
            className="d-inline-block rounded-circle position-absolute d-flex flex-row justify-content-center align-items-center font-weight-bold text-white"
            style={{
              width: 20,
              height: 20,
              right: "0.5rem",
              top: "0.5rem",
              background: "#3B44AC",
              fontSize: "0.8em",
            }}
          >
            {chatUser.unreadCount > 9 ? `+9` : chatUser.unreadCount}
          </div>
        )}
        <div
          className="rounded-circle overflow-hidden position-relative d-flex flex-row align-items-center justify-content-center flex-wrap"
          style={{ width: 50, height: 50 }}
        >
          {userList.length > 2 && (
            <div className="w-100 h-100 d-flex flex-row flex-wrap">
              {userList.map((userPic, index) => {
                return (
                  <div
                    key={index}
                    className={`${(index + 1) % 2 === 0 ? "border-left" : ""} ${
                      index > 1 ? "border-top" : ""
                    }`}
                    style={{
                      background: `url(${
                        userPic.UserPIC ? userPic.UserPIC : user_default_image
                      }) 50% 50% / contain no-repeat`,
                      width:
                        index + 1 === userList.length && userList.length === 3
                          ? "100%"
                          : "50%",
                      height: "50%",
                    }}
                  />
                );
              })}
            </div>
          )}

          {userList.length <= 2 && (
            <div
              className="w-100 h-100"
              style={{
                background: `url(${
                  userList[0].UserPIC ? userList[0].UserPIC : user_default_image
                }) #fff 50% 50% / cover no-repeat`,
                width: 50,
                height: 50,
              }}
            />
          )}
          {userList.length === 0 && (
            <div
              className="w-100 h-100"
              style={{
                background: `url(${
                  chatUser.UserPIC ? chatUser.UserPIC : user_default_image
                }) #fff 50% 50% / cover no-repeat`,
                width: 50,
                height: 50,
              }}
            />
          )}
        </div>

        <div className="px-2 flex-grow-1">
          <div className="d-flex flex-row  align-items-center">
            <div className="font-weight-bold m-0 p-0 d-flex flex-row justify-content-start">
              <div className="text-truncate" style={{ maxWidth: 250 }}>
                {Number(chat.crmType) !== 1 && (
                  <span
                    className="rounded-pill bg-light d-inline-block p-1 mr-1"
                    style={{ fontSize: "0.6em" }}
                  >
                    <i className="fa-solid fa-user-group" />{" "}
                    {chat.chatUserList.length}
                  </span>
                )}
                {loginInfo.selfChatRoomKey === chat.crmMainKey && (
                  <span
                    className="d-inline-flex flex-row justify-content-center align-items-center bg-secondary text-white text-center rounded-circle mr-1"
                    style={{
                      width: 16,
                      height: 16,
                      fontSize: "0.8em",
                    }}
                  >
                    나
                  </span>
                )}
                {chat.chatRoomTitle}
              </div>
            </div>
            {alertDisabledList.hasOwnProperty(chat.crmMainKey) && (
              <i className="far fa-bell-slash ml-2 text-danger" />
            )}
          </div>
          <p
            className="m-0"
            style={{
              maxWidth: 200,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              wordBreak: "keep-all",
            }}
          >
            {chatUser.cruAgreeYN === "Y" ? chat.crmLastMSG : msg}
          </p>
          <p className="m-0 font-size-08">
            {new Date(chat.crmLastTime).toLocaleString()}
          </p>
        </div>
      </div>
    );
  }
});

const ChatNewsItem = React.memo((props) => {
  const { searchText, item, openNewsChatRoom, alertDisabledList } = props;

  let isDisplay = true;
  if (searchText !== "" && item.cnmName.indexOf(searchText) === -1) {
    isDisplay = false;
  }

  const pic =
    item.COMP_LOGO !== "" ? item.COMP_LOGO.split("?")[0] : user_default_image;
  const unread = Number(item.cnmUnreadCnt);

  return (
    <div
      className={`${
        isDisplay ? "d-flex" : "d-none"
      }  flex-row justify-content-start align-items-center cursor_pointer position-relative p-2 mb-2 rounded-lg bg-blue-light`}
      onClick={() => openNewsChatRoom(item)}
    >
      {unread > 0 && (
        <div
          className="d-inline-block rounded-circle position-absolute d-flex flex-row justify-content-center align-items-center font-weight-bold text-white"
          style={{
            width: 20,
            height: 20,
            right: 10,
            bottom: 30,
            background: "#3B44AC",
            fontSize: "0.8em",
          }}
        >
          {unread > 9 ? `+9` : unread}
        </div>
      )}
      <div
        className="rounded-circle overflow-hidden position-relative d-flex flex-row align-items-center justify-content-center flex-wrap"
        style={{ width: 50, height: 50 }}
      >
        <div
          className="w-100 h-100"
          style={{
            background: `url(${pic}) #fff 50% 50% / contain no-repeat`,
            width: 50,
            height: 50,
          }}
        />
      </div>
      <div className="px-2 flex-grow-1">
        <div className="d-flex flex-row  align-items-center">
          <div className="font-weight-bold m-0 p-0 d-flex flex-row justify-content-start">
            <div className="text-truncate" style={{ maxWidth: 250 }}>
              {item.cnmName}
              {alertDisabledList.hasOwnProperty(item.cnmMainKey) && (
                <i
                  className="far fa-bell-slash ml-2 text-danger"
                  style={{ minWidth: 20 }}
                />
              )}
            </div>
          </div>
        </div>
        <p
          className="m-0"
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
          }}
        >
          {item.cnmDesc}
        </p>
        <p className="m-0 font-size-08">
          {new Date(item.cnmLastTime).toLocaleString()}
        </p>
      </div>
    </div>
  );
});
