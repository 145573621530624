/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";

import LogiTalkChatRoomNoticeList from "./notice/LogiTalkChatRoomNoticeList";
import LogiTalkChatRoomNotice from "./notice/LogiTalkChatRoomNotice";
import Linkify from "react-linkify";

import ChatRoomFiles from "./chatRoomFiles/ChatRoomFiles";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
import Utilities from "../../lib/Utilities";
import InputEmoji from "react-input-emoji";
import $ from "jquery";
import axios from "axios";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "LogiTalkChatRoom";
const user_default_image = "https://www.gbts.co.kr/images/user_pic-50x50.png";

let chData = []; //채팅이력
let sendFileData = [];
let now = new Date(); // 현재 날짜 및 시간
let standardDate = new Date(now.setDate(now.getDate() + 1)); // 내일
let isDisabledChat = false;
let isAllowBackup = false;

let notice_list = [];
let current_notice = {};

let msg_search_amount = 0; //검색결과 총개수
let msg_search_current_amount = 0; //현재 채팅 검색결과 개수
let msg_search_items = []; //현재 채팅리스트의 검색 아이템 수
let msg_search_index = -1; //현재 채팅 검색아이템 중 현재 선택된 index
let msg_search_word = "";
let mFindReplyCount = 0;

export default function LogiTalkChatRoom(props) {
  const {
    loginInfo,
    activeChRoom,
    isMobile,
    alertDisabledList,
    delActiveChRoom,
    changedActiveMainMenu,
    updateAlertDisable,
    deleteChatRoom,
    isWindowActive,
    user_list,
  } = props;

  const [chUserData, setChUserData] = useState(""); // 채팅방 내 유저 정보
  const [isAllow, setIsAllow] = useState(false); // 채팅 수락 거절 여부 true :수락 , false : 거절

  const [isLoading, setIsLoading] = useState(false);

  const [scrollBtn, setScrollBtn] = useState(false); // 하단이동버든 display여부
  const [beforeScroll, setBeforeScroll] = useState(0); // 이전 스크롤 값 저장
  const [isScroll, setIsScroll] = useState(false); //현제 스크롤 여부 확인

  const [chList, setChList] = useState([]); // 이전채팅이력
  const [msgListFilter, setMsgListFilter] = useState({}); //채팅이력 마지막 메세지 IDX
  const [msgEnd, setMsgEnd] = useState(false); // 첫 메세지 여부 확인

  const [activeAlert, setActiveAlert] = useState(true); // 알림 끄기 켜기
  const [newMsg, setNewMsg] = useState(""); //신규메세지
  const [chatTitleChange, setChatTitleChange] = useState(false); //채팅 타이틀 변경 활성화
  const [chatTitle, setChatTitle] = useState(""); // 채팅 타이릍
  const [chUserListView, setChUserListView] = useState(false); //대화상대 목록보기
  const [text, setText] = useState(); //react-input-emoji 글자입력

  const [isFileShow, setIsFileShow] = useState(false); //파일리스트 보기
  const [fileList, setFileList] = useState([]); //파일리스트
  const [isNoticeListShow, setIsNoticeListShow] = useState(false); //공지리스트 보기
  const [isNoticeShow, setIsNoticeShow] = useState(true); //공지리스트 보기
  const [isDragging, setIsDragging] = useState(false); // 드래드 여부 확인

  const [sendFileList, setSendFileList] = useState([]); // 첨부파일 리스트

  const [chatSmoothScroll, setChatSmoothScroll] = useState(false);
  const [replyData, setReplyData] = useState(null);
  const [isMSGSearch, setIsMSGSearch] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const msgSearchInput = useRef();
  const msgInput = useRef();
  const emgInput = useRef();
  const chTitle = useRef();
  const chatRoomDragRef = useRef(null);

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_TALK_CHAT_ROOM_NOTICE_CHANGE,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (activeChRoom.crmMainKey === n["data"].crtMainKey) {
          SELECT_TALK_NOTICE_LIST();
        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_TALK_NOTICE_DATA_UPDATE,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (n.crnRoomKey !== activeChRoom.crmMainKey) return;
        SELECT_TALK_NOTICE_LIST();
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_TALK_CHAT_READ,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n["returnData"].length) {
            if (n["returnData"][0].crmMainKey !== activeChRoom.crmMainKey) {
              return;
            }
            let users = { ...chUserData };
            let dataList = [...n["returnData"][0].chatUserList];
            for (let i = 0; i < dataList.length; i++) {
              users[`${dataList[i].cruUserSite}_${dataList[i].cruUserID}`] = {
                ...dataList[i],
              };
            }
            setChUserData(users);
          }
        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_TALK_MSG_SEND,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n.ret) {
            if (activeChRoom.crmMainKey !== n["returnData"][0].crmMainKey)
              return;

            let newMsg = {
              ...n.data,
              crtCreatDate: new Date(),
              crtDelYN: "N",
              crtIDX:
                chList.length - 1 > -1
                  ? Number(chList[chList.length - 1].crtIDX) + 1
                  : 0,
            };

            let dataList = [...chList, newMsg];
            chData = [...dataList];

            if (
              n.data.senderUserID !== loginInfo.user_id ||
              n.data.senderCompCD !== loginInfo.comp_cd
            ) {
              setNewMsg(newMsg);
            }

            setChList([...dataList]);

            if (
              newMsg.senderUserID === loginInfo.user_id &&
              newMsg.senderCompCD === loginInfo.comp_cd
            ) {
              moveToChatScroll();
              sendFileData = [];
              setSendFileList([]);
            }

            let chatDiv = document.querySelector("#chat");
            if (
              chatDiv.scrollTop + chatDiv.clientHeight >=
              chatDiv.scrollHeight - 400
            ) {
              moveToChatScroll();
            }
          }
        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_ROOM_INVITE,
      _mClassName,
      (b, n) => {
        if (!n || !n.ret) return;

        let chatUsers = { ...chUserData };
        n["returnData"][0].chatUserList.forEach((value) => {
          chatUsers[`${value.cruUserSite}_${value.cruUserID}`] = { ...value };
        });
        setChUserData(chatUsers);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_TALK_LEAVE_ROOM,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n.ret) {
            if (activeChRoom.crmMainKey !== n["returnData"][0].crmMainKey)
              return;

            let newMsg = {
              ...n.data,
              crtCreatDate: new Date(),
            };

            newMsg.crtIDX =
              chList.length - 1 > -1
                ? Number(chList[chList.length - 1].crtIDX) + 1
                : 0;

            let dataList = [...chList, newMsg];
            chData = [...dataList];

            if (
              n.data.senderUserID !== loginInfo.user_id ||
              n.data.senderCompCD !== loginInfo.comp_cd
            ) {
              setNewMsg(newMsg);
            }

            let chatUsers = { ...chUserData };
            if (
              chatUsers.hasOwnProperty(
                `${newMsg.senderCompCD}_${newMsg.senderUserID}`
              )
            ) {
              delete chatUsers[`${newMsg.senderCompCD}_${newMsg.senderUserID}`];
            }
            setChUserData(chatUsers);
            setChList([...dataList]);
            // EVENT_SOCKET_TALK_CHAT_READ(newMsg);
            let chatDiv = document.querySelector("#chat");

            if (
              chatDiv.scrollTop + chatDiv.clientHeight >=
              chatDiv.scrollHeight - 100
            ) {
              moveToChatScroll();
            }
          }
        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_USER_PROFILE_CHANGED,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n["returnData"].length) {
            let data = { ...n["returnData"][0] };
            let usersData = { ...chUserData };
            usersData[`${data.COMP_CD}_${data.USER_ID}`] = {
              ...chUserData[`${data.COMP_CD}_${data.USER_ID}`],
              UserNICK: data.USER_NICK,
              UserPART: data.USER_PART_NM,
              UserTEAM: data.USER_TEAM_NM,
              UserPIC: data.USER_PIC,
            };
            setChUserData(usersData);
          }
        }
      }
    );

    document.onpaste = function (event) {
      let items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      for (let index in items) {
        let item = items[index];
        if (item.kind === "file") {
          let blob = item.getAsFile();
          let reader = new FileReader();
          reader.onload = function (event) {
            let file = new File([blob], blob.name, { type: blob.type });
            handleUpload(file);
          };
          reader.readAsArrayBuffer(blob);
        }
      }
    };

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_MSG_DELETE,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (n.ret === false) return;
        if (n["returnData"][0].crmMainKey !== activeChRoom.crmMainKey) return;
        let dataList = [...chList];
        let deleteIndex = dataList.findIndex(
          (msg) => msg.crtMSGKey === n.data.crtMSGKey
        );

        dataList[deleteIndex].crtMSG = "삭제된 메세지입니다.";
        dataList[deleteIndex].crtDelYN = "Y";
        chData = dataList;
        setChList(dataList);
      }
    );
  })();

  useEffect(() => {
    GET_TALK_MSG_LIST(moveToChatScroll);
    SELECT_TALK_NOTICE_LIST();
    for (let key in alertDisabledList) {
      if (key === activeChRoom.crmMainKey) setActiveAlert(false);
    }
    if (document.querySelector("#chat")) {
      document
        .querySelector("#chat")
        .addEventListener("scroll", currentScrollCheck);
    }
    return () => {
      chData = []; //채팅이력
      sendFileData = [];
      now = new Date(); // 현재 날짜 및 시간
      standardDate = new Date(now.setDate(now.getDate() + 1)); // 내일
      isDisabledChat = false;
      isAllowBackup = false;

      notice_list = [];
      current_notice = {};

      msg_search_amount = 0; //검색결과 총개수
      msg_search_current_amount = 0; //현재 채팅 검색결과 개수
      msg_search_items = []; //현재 채팅리스트의 검색 아이템 수
      msg_search_index = -1; //현재 채팅 검색아이템 중 현재 선택된 index
      msg_search_word = "";
      mFindReplyCount = 0;

      socket.removeLocalEventListener(
        MsgIDList.EVENT_TALK_CHAT_ROOM_NOTICE_CHANGE,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_TALK_NOTICE_DATA_UPDATE,
        _mClassName
      );
      if (document.querySelector("#chat")) {
        document
          .querySelector("#chat")
          .removeEventListener("scroll", currentScrollCheck);
      }

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_TALK_CHAT_READ,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_TALK_MSG_SEND,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_ROOM_INVITE,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_TALK_LEAVE_ROOM,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_MSG_DELETE,
        _mClassName
      );
      isDisabledChat = false;
    };
  }, []);

  useEffect(() => {
    if (activeChRoom && activeChRoom.crmType === "1") {
      for (let key in chUserData) {
        if (
          chUserData[key].cruUserSite !== loginInfo.comp_cd ||
          chUserData[key].cruUserID !== loginInfo.user_id
        ) {
          isDisabledChat = chUserData[key].USE_GB === "D";
        }
      }
    }
  }, [chUserData]);

  useEffect(() => {
    if (newMsg) {
      setTimeout(() => {
        setNewMsg("");
      }, 2000);
    }
  }, [newMsg]);

  useEffect(() => {
    if (activeChRoom) {
      chatUserListHandler(activeChRoom["chatUserList"]);
    }
  }, [activeChRoom]);

  useEffect(() => {
    createChatTitle();
  }, [chUserData]);

  useEffect(() => {
    if (isFileShow) {
      GET_TALK_MSG_FILE_LIST();
    }
  }, [isFileShow]);

  useEffect(() => {
    if (isAllowBackup) {
      GET_TALK_MSG_LIST();
      SELECT_TALK_NOTICE_LIST();
    }
  }, [isAllow]);

  useEffect(() => {
    if (chList.length && isWindowActive) {
      EVENT_SOCKET_TALK_CHAT_READ(chList[chList.length - 1].crtMSGKey);
    }
  }, [chList, isWindowActive]);

  //대화상대 및 승락 여부
  const chatUserListHandler = (dataList) => {
    let chatUserList = {};
    let allow = false;

    for (let i = 0; i < dataList.length; i++) {
      chatUserList[`${dataList[i].cruUserSite}_${dataList[i].cruUserID}`] = {
        ...dataList[i],
        UserNICK: dataList[i].UserNICK || dataList[i].UserName,
      };
      if (
        dataList[i].cruUserID === loginInfo.user_id &&
        dataList[i].cruUserSite === loginInfo.comp_cd
      ) {
        allow = dataList[i].cruAgreeYN === "Y";
      }
    }
    isAllowBackup = allow;
    setIsAllow(allow);
    setChUserData(chatUserList);
  };

  //공지사항 리스트 받아오기
  const SELECT_TALK_NOTICE_LIST = () => {
    if (!isAllowBackup) return;
    let msgID = util.makeUUIDv4();
    let data = {
      file: "/talk_notice/JS_talk_notice_select.php",
      msgID: msgID,
      crmMainKey: activeChRoom.crmMainKey,
    };

    socket.sendMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"] && newData["returnData"].length > 0) {
          let data_list = [...newData["returnData"]];

          for (let i = 0; i < data_list.length; i++) {
            let data = data_list[i];
            if (data.crnFiles !== "") {
              let split_data = data.crnFiles.split("|");
              let fileList = [];
              for (let j = 0; j < split_data.length; j++) {
                let file_split = split_data[j].split("?");
                let file = {
                  fileLink: file_split[0],
                  fileName: file_split[1],
                };
                fileList.push(file);
              }
              data_list[i].crnFiles = fileList;
            } else {
              data_list[i].crnFiles = [];
            }
          }
          notice_list = [...data_list];
          let display_notice = notice_list.find(
            (item) => Number(item.crnIsTop) === 0
          );
          if (display_notice) {
            current_notice = display_notice;
          }
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  //채팅 기록  가져오기
  const GET_TALK_MSG_LIST = (callback, prop) => {
    if (!isAllowBackup) return;
    let msgID = util.makeUUIDv4();
    let talkCount = 50;
    let data = {
      method: MsgIDList.EVENT_SOCKET_ROOM_MSG_LIST_GET,
      file: "/talk/JS_talk_room_msg_list.php",
      msgID: msgID,
      take: talkCount,
      skip: 0,
      search_text: msg_search_word,
      crtMainKey: activeChRoom.crmMainKey,
      ...msgListFilter,
    };

    if (prop) {
      if (prop.hasOwnProperty("crtIDX")) {
        data = { ...data, ...prop };
      }
    }

    socket.sendMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.search_text !== "" && Number(newData["cnt"]) === 0) {
          codeList.Modal.current.alert(
            `[${newData.search_text}]가 포함된 메세지가 없습니다.`
          );
          return;
        }
        let dataList = [...chList];
        if (newData["returnData"].length) {
          setChatSmoothScroll(false);
          dataList = [...newData["returnData"].reverse(), ...chList];
          chData = [...dataList];
          setMsgListFilter({ crtIDX: dataList[0].crtIDX });
          setChList([...dataList]);
        }
        if (newData["returnData"].length < talkCount) {
          setMsgEnd(true);
        }
        if (callback) {
          callback(newData, dataList);
        } else {
          moveChatScroll();
        }
      }
    });
  };

  //스크롤 하단이동
  const moveChatScroll = () => {
    let chatDIV = document.querySelector("#chat");
    document.querySelector("#chat").scrollTop =
      chatDIV.scrollHeight - beforeScroll;
    setBeforeScroll(chatDIV.scrollHeight);
    setChatSmoothScroll(true);
  };

  //채팅 파일리스트 받아오기
  const GET_TALK_MSG_FILE_LIST = () => {
    if (!isAllow) return;

    let msgID = util.makeUUIDv4();
    let talkCount = 50;

    let data = {
      method: MsgIDList.EVENT_SOCKET_ROOM_MSG_LIST_GET,
      file: "/talk/JS_talk_room_msg_file_list.php",
      msgID: msgID,
      take: talkCount,
      skip: 0,
      crtMainKey: activeChRoom.crmMainKey,
      crtType: 2,
      crtDelYN: "N",
    };
    socket.sendMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length) {
          let dataList = [...newData["returnData"]];
          dataList.sort(function (a, b) {
            a = new Date(a.crmLastTime);
            b = new Date(b.crmLastTime);
            return a > b ? -1 : a < b ? 1 : 0;
          });
          setFileList(dataList);
        }
      }
    });
  };

  //읽은 메세지
  const EVENT_SOCKET_TALK_CHAT_READ = (msgKey) => {
    if (!isWindowActive) return;
    if (!isAllow) return;
    if (!msgKey) return;

    let msgID = util.makeUUIDv4();
    let data = {
      method: MsgIDList.EVENT_SOCKET_TALK_CHAT_READ,
      msgID: msgID,
      data: {
        crtMainKey: activeChRoom.crmMainKey,
        crtMSGKey: msgKey,
        senderCompCD: loginInfo.comp_cd,
        senderUserID: loginInfo.user_id,
      },
    };

    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
  };

  //첨부파일 서버 업로드
  const handleUpload = async (file) => {
    console.log("handleUpload");
    const region = `talk/chatRoom/${loginInfo.comp_cd}/${loginInfo.user_id}`;
    let name = file.name;
    name = name.replace(/\s+/g, "_");

    let reg = /[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    name = name.replace(reg, "");
    //let timestamp = new Date().getTime();
    let newName = util.todayDateTime() + "_" + name;
    let blob = file.slice(0, file.size, file.type);
    let newFile = new File([blob], newName, { type: file.type });

    if (newFile.size > 21000000) {
      alert("20MB 이하의 파일만 업로드 가능합니다.");
      return;
    }
    const formData = new FormData();
    formData.append("file", newFile);
    formData.append("region", region);
    console.log(newFile);
    await axios
      .post("https://www.gbts.co.kr/backside/aws/uploadfile.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let dataList = [...sendFileData, `${response.data["url"]}?${name}`];
        sendFileData = dataList;

        setSendFileList(dataList);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  //사용자 초대
  const addToChatUser = () => {
    changedActiveMainMenu("LogiTalkUserList");
    socket.fireLocalEventListener(
      MsgIDList.EVENT_TALK_ADD_CHAT_USER,
      _mClassName,
      {
        chUserData: chUserData,
        crmType: activeChRoom.crmType,
        crmMainKey: activeChRoom.crmMainKey,
      },
      () => {}
    );
  };

  //대화 요청 수락, 거절
  const TALK_ALLOW = (ret) => {
    let msgID = util.makeUUIDv4();
    let data = {
      method: MsgIDList.EVENT_SOCKET_TALK_ALLOW,
      msgID: msgID,
      data: {
        cruMainKey: activeChRoom.crmMainKey,
        cruUserSite: loginInfo.comp_cd,
        cruUserID: loginInfo.user_id,
        cruAgreeYN: ret,
        crmLastMSG: `${loginInfo.user_nm}님이 대화를 ${
          ret === "Y" ? "수락" : "거절"
        }하였습니다.`,
        senderCompCD: loginInfo.comp_cd,
        senderUserID: loginInfo.user_id,
        senderUserName: loginInfo.user_nm,
        senderUserNICK: loginInfo.USER_NICK,
        senderUserPIC: loginInfo.USER_PIC,
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          isAllowBackup = ret === "Y";
          setIsAllow(ret === "Y");
        }
      }
    });
  };

  //메세지 소켓전송
  const TALK_MSG_SEND = () => {
    let msg = msgInput.current.value.trim();
    let files = [...sendFileList];

    if (!isAllow || (msg.trim() === "" && files.join("").trim() === "")) return;

    let crtType = 1;
    if (files.length > 0) {
      crtType = 2;
      files = files.join("|");
      if (msg === "") {
        msg = `${
          loginInfo.USER_NICK ? loginInfo.USER_NICK : loginInfo.user_nm
        }님이 파일을 전송하였습니다.`;
      }
    } else {
      files = "";
    }
    if (msg.indexOf("|¿|") > -1) {
      msg = msg.split("|¿|").join("");
    }

    if (replyData !== null) {
      let replyUser = "";
      let replyMSG = "";

      replyUser = replyData.senderUserNICK
        ? replyData.senderUserNICK
        : replyData.senderUserName;
      replyMSG = replyData.crtMSG;

      crtType = "5";
      msg = `${replyUser}|¿|${replyMSG}|¿|${replyData.crtMSGKey}|¿|` + msg;
      setReplyData(null);
    }

    let msgID = util.makeUUIDv4();
    let data = {
      method: MsgIDList.EVENT_SOCKET_TALK_MSG_SEND,
      msgID: msgID,
      data: {
        crtMainKey: activeChRoom.crmMainKey,
        crtMSGKey: msgID,
        crtType: crtType, //1: msg 2: file
        crtMSG: msg,
        crtFile: files ? files : "",
        crtCreator: activeChRoom.crtCreator,
        crtCreatorSite: activeChRoom.crtCreatorSite,
        senderCompCD: loginInfo.comp_cd,
        senderUserID: loginInfo.user_id,
        senderUserName: loginInfo.user_nm,
        senderUserNICK: loginInfo.USER_NICK,
        senderUserPART: loginInfo.USER_PART_NM,
        senderUserTEAM: loginInfo.USER_TEAM_NM,
        senderUserPIC: loginInfo.USER_PIC
          ? loginInfo.USER_PIC
          : user_default_image,
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
  };

  //----------delete message-----------------------
  const EVENT_SOCKET_MSG_DELETE = (ch) => {
    if (!ch) return;
    if (
      ch.senderUserID !== loginInfo.user_id ||
      ch.senderCompCD !== loginInfo.comp_cd
    )
      return;
    let msgID = util.makeUUIDv4();

    let data = {
      method: MsgIDList.EVENT_SOCKET_MSG_DELETE,
      msgID: msgID,
      data: {
        crtIDX: ch.crtIDX,
        crtMainKey: ch.crtMainKey, //룸 메인키
        crtMSGKey: ch.crtMSGKey, //생성 메세지키
        senderCompCD: loginInfo.comp_cd,
        senderUserID: loginInfo.user_id,
      },
    };
    codeList.Modal.current.confirm(
      `msg : ${ch.crtMSG}\n해당메세지를 삭제하시겠습니까?`,
      (ret) => {
        if (ret) {
          socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
        }
      }
    );
  };

  //--------out to chatting room
  const EVENT_SOCKET_TALK_LEAVE_ROOM = () => {
    let msgID = util.makeUUIDv4();

    let data = {
      method: MsgIDList.EVENT_SOCKET_TALK_LEAVE_ROOM,
      msgID: msgID,
      data: {
        crtMainKey: activeChRoom.crmMainKey, //룸 메인키
        crtMSGKey: activeChRoom.crtMSGKey, //생성 메세지키
        crtType: 3, //시스템메세지로세팅
        crtMSG: `[${loginInfo.comp_nm}] ${
          loginInfo.USER_NICK || loginInfo.USER_NM
        }님이 대화방을 나갔습니다`, //출력할내용
        crtFile: "",
        crmLastMSG: `[${loginInfo.comp_nm}] ${
          loginInfo.USER_NICK || loginInfo.USER_NM
        }님이 대화방을 나갔습니다`, //출력할내용
        crtCreator: loginInfo.user_id, //나가는사람 ID
        crtCreatorSite: loginInfo.comp_cd, //나가는사람 COMP_CD
        senderCompCD: loginInfo.comp_cd,
        senderUserID: loginInfo.user_id,
        senderUserName: loginInfo.user_nm,
        senderUserNICK: loginInfo.USER_NICK || loginInfo.USER_NM,
        senderUserPART: loginInfo.USER_PART_NM,
        senderUserTEAM: loginInfo.USER_TEAM_NM,
        senderUserPIC: loginInfo.USER_PIC,
      },
    };
    if (Number(activeChRoom.crmType) !== 1) {
      socket.sendSocketMessage(data, msgID, (beforeData, newData) => {
        if (newData) {
          if (!newData.ret) {
            codeList.Modal.current.alert(
              "오류로 인해 채팅방을 나갈 수 없습니다. 관리자에게 문의 바랍니다."
            );
          }
        }
      });
    } else {
      deleteChatRoom(activeChRoom);
    }
  };

  //----------user interface handler------------------

  //스크롤 여부확인
  const currentScrollCheck = () => {
    try {
      let chat = document.querySelector(`#chat`);
      if (chat.scrollTop <= 50) {
        setIsNoticeShow(false);
      } else {
        setIsNoticeShow(true);
      }

      if (chat.scrollHeight > chat.scrollTop + chat.clientHeight) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
        setNewMsg("");
      }
    } catch (e) {}
  };

  //하단 이동 버튼 display
  const scrollBtnHandler = () => {
    let chat = document.querySelector(`#chat`);
    if (chat.scrollTop < chat.scrollHeight - chat.clientHeight) {
      setScrollBtn(true);
    } else {
      setScrollBtn(false);
    }
  };

  //스크롤 하단 이동
  const moveToChatScroll = () => {
    let chatDIV = document.querySelector("#chat");
    chatDIV.scrollTop = chatDIV.scrollHeight;
  };

  //채팅방 닫기
  const parentChDel = () => {
    delActiveChRoom(activeChRoom);
  };

  //---------split time-------------
  const delTimeSec = (time) => {
    if (!time) return;
    let data = time.toString().split(":");
    if (data.length === 0) return;
    return `${data[0]}:${data[1]}`;
  };

  //creative time separator
  const dateSeparator = (data) => {
    let date = new Date(data);
    let separator = "";

    let standardDateYear = standardDate.getFullYear();
    let standardDateMonth = standardDate.getMonth();
    let standardDateDay = standardDate.getDate();

    let crtYear = date.getFullYear();
    let crtMonth = date.getMonth();
    let crtDate = date.getDate();

    if (
      standardDateYear !== crtYear ||
      standardDateMonth !== crtMonth ||
      standardDateDay !== crtDate
    ) {
      standardDate = date;
      separator = (
        <div className="d-flex flex-row align-items-center py-2">
          <div className="border border-bottom-0 flex-grow-1" />
          <div className="text-secondary  px-2">
            {date.toLocaleDateString()}
          </div>
          <div className="border border-bottom-0 flex-grow-1" />
        </div>
      );
    }

    return separator;
  };

  //set chatting title
  const createChatTitle = () => {
    let userNames = [];
    let cruRoomName = "";
    let isMe = "(알수없음)";
    let chatUsers = { ...chUserData };
    for (let key in chatUsers) {
      let isGuest = chatUsers[key].cruUserSite.indexOf("-") > -1;

      if (
        chatUsers[key].cruUserID !== loginInfo.user_id ||
        chatUsers[key].cruUserSite !== loginInfo.comp_cd
      ) {
        chatUsers[key].UserNICK =
          chatUsers[key].UserNICK || chatUsers[key].UserName;
        chatUsers[key].UserPIC = chatUsers[key].UserPIC || user_default_image;
        userNames.push(
          `[${isGuest ? "GUEST" : chatUsers[key].UserCOMPNM}] ${
            chatUsers[key].UserNICK || chatUsers[key].UserName
          }`
        );
      } else {
        isMe = `[${isGuest ? "GUEST" : chatUsers[key].UserCOMPNM}] ${
          chatUsers[key].UserNICK || chatUsers[key].UserName
        }`;
        if (chatUsers[key].hasOwnProperty("cruRoomName")) {
          if (chatUsers[key].cruRoomName !== "") {
            userNames = [chatUsers[key].cruRoomName];
            isMe = chatUsers[key].cruRoomName;
            break;
          }
        }
      }
    }
    if (userNames.length) {
      cruRoomName = userNames.join(", ");
    } else {
      cruRoomName = isMe;
    }

    setChatTitle(cruRoomName);
  };

  //change chatting title
  const changeChatTitle = () => {
    let val = chTitle.current.value;
    let msgID = util.makeUUIDv4();
    let data = {
      method: MsgIDList.EVENT_TALK_CHAT_ROOM_TITLE_CHANGE,
      msgID: msgID,
      data: {
        cruRoomName: val, //사용자지정 룸네임
        cruMainKey: activeChRoom.crmMainKey, //방키
        cruUserSite: loginInfo.comp_cd, //사용자compcd
        cruUserID: loginInfo.user_id, //user id},
      },
    };

    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          socket.fireLocalEventListener(
            MsgIDList.EVENT_TALK_CHAT_ROOM_TITLE_CHANGE,
            _mClassName,
            data.data,
            () => {}
          );

          setChatTitle(val);
          setChatTitleChange(false);
        }
      }
    });
  };

  //---------search msg--------------

  //검색여부 상태 변경
  const handleSearchMsgState = () => {
    if (isMSGSearch) {
      msgSearchInput.current.value = "";
      msg_search_items = [];
      msg_search_amount = 0;
      msg_search_index = -1;
      msg_search_word = "";
    }
    setIsMSGSearch(!isMSGSearch);
  };

  //검색
  const actionSearchMsg = () => {
    //검색 초기화
    msg_search_items = [];
    msg_search_amount = 0;
    msg_search_index = -1;
    msg_search_word = "";
    //검색어 전역변수 저장
    msg_search_word = msgSearchInput.current.value;

    //빈값 여부 확인
    if (msg_search_word.trim() !== "") {
      GET_TALK_MSG_LIST((newData) => {
        if (Number(newData["cnt"]) > 0) {
          msg_search_amount = Number(newData["cnt"]);
          let filtered = chData.filter((product) => {
            let msg = product.crtMSG;
            msg = msg.indexOf("|¿|") > -1 ? msg.split("|¿|")[3] : msg;
            if (msg.indexOf(msg_search_word) > -1) return product;
          });

          if (filtered.length > 0) {
            //  현재 채팅에 검색어 존재
            msg_search_items = filtered;
            msg_search_current_amount = filtered.length;
            msg_search_index = filtered.length - 1;

            if (
              msg_search_items[msg_search_index] &&
              msg_search_items[msg_search_index].hasOwnProperty("crtMSGKey")
            ) {
              const element = document.getElementById(
                msg_search_items[msg_search_index].crtMSGKey
              );
              element.scrollIntoView({ block: "center" });
              setIsNeedUpdate(!isNeedUpdate);
            }
          }
          codeList.Modal.current.alert(
            `총 ${msg_search_amount}개의 메세지가 검색되었습니다.`
          );
        } else {
          codeList.Modal.current.alert(
            `[${msg_search_word}](이)가 포함된 메세지가 존재하지 않습니다.`
          );
        }
      });
    } else {
      codeList.Modal.current.alert("검색어를 입력해주세요");
    }
  };

  //검색후 상단 이동
  const moveUpSearchMsg = () => {
    setIsLoading(true);
    if (msg_search_current_amount === 0) {
      // 현재 목록에 검색 메세지가 없음
      GET_TALK_MSG_LIST((newData) => {
        let filtered = chData.filter((product) => {
          let msg = product.crtMSG;
          msg = msg.indexOf("|¿|") > -1 ? msg.split("|¿|")[3] : msg;
          if (msg.indexOf(msg_search_word) > -1) return product;
        });

        if (filtered.length > 0) {
          //  현재 채팅에 검색어 존재
          msg_search_items = filtered;
          msg_search_current_amount = filtered.length;
          msg_search_index = filtered.length - 1;

          if (
            msg_search_items[msg_search_index] &&
            msg_search_items[msg_search_index].hasOwnProperty("crtMSGKey")
          ) {
            const element = document.getElementById(
              msg_search_items[msg_search_index].crtMSGKey
            );
            element.scrollIntoView({ block: "center", behavior: "smooth" });
            setIsNeedUpdate(!isNeedUpdate);
          }
        } else {
          // 현재 채팅 목록에 검색어가 존재하지 않을경우 상단이동만 실행
          const element = document.getElementById(chData[0].crtMSGKey);
          element.scrollIntoView({ block: "center", behavior: "smooth" });
          setIsNeedUpdate(!isNeedUpdate);
        }
        setIsLoading(false);
      });
    } else {
      if (
        msg_search_index === 0 &&
        msg_search_current_amount < msg_search_amount
      ) {
        // 불러온 검색 메세지를 전부 조회 하고 추가 조회함
        GET_TALK_MSG_LIST(() => {
          let filtered = chList.filter((product) => {
            let msg = product.crtMSG;
            msg = msg.indexOf("|¿|") > -1 ? msg.split("|¿|")[3] : msg;
            if (msg.indexOf(msg_search_word) > -1 && product.crtDelYN === "N")
              return product;
          });
          if (msg_search_current_amount < filtered.length) {
            let index = filtered.length - msg_search_current_amount - 1;
            msg_search_index = index;
          }
          msg_search_current_amount = filtered.length;
          msg_search_items = filtered;
          if (
            msg_search_items[msg_search_index] &&
            msg_search_items[msg_search_index].hasOwnProperty("crtMSGKey")
          ) {
            const element = document.getElementById(
              msg_search_items[msg_search_index].crtMSGKey
            );
            element.scrollIntoView({ block: "center" });
            setIsNeedUpdate(!isNeedUpdate);
          }
          if (msg_search_index === 0) {
            document.querySelector("#chat").scrollTo({ top: 0 });
          }
          setIsLoading(false);
        });
      } else {
        //  현재목록에 검색메세지가 있고 전부 확인하지 않음
        msg_search_index = msg_search_index - 1;
        if (
          msg_search_items[msg_search_index] &&
          msg_search_items[msg_search_index].hasOwnProperty("crtMSGKey")
        ) {
          const element = document.getElementById(
            msg_search_items[msg_search_index].crtMSGKey
          );
          element.scrollIntoView({ block: "center" });
          setIsNeedUpdate(!isNeedUpdate);
        }
        setIsLoading(false);
      }
    }
  };

  //검색 후 하단 이동
  const moveDownSearchMsg = () => {
    msg_search_index = msg_search_index + 1;
    let filtered = chData.filter((product) => {
      let msg = product.crtMSG;
      msg = msg.indexOf("|¿|") > -1 ? msg.split("|¿|")[3] : msg;
      if (msg.indexOf(msg_search_word) > -1 && product.crtDelYN === "N")
        return product;
    });

    const element = document.getElementById(
      filtered[msg_search_index].crtMSGKey
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setIsNeedUpdate(!isNeedUpdate);
  };

  //---------drag & drop--------------

  const onChangeFiles = useCallback(
    (e) => {
      let selectFiles = [];
      if (e.type === "drop") {
        selectFiles = e.dataTransfer.files;
      } else {
        selectFiles = e.target.files;
      }
      for (const file of selectFiles) {
        handleUpload(file);
      }
    },

    [sendFileList]
  );

  const handleFilterFile = useCallback(
    (id) => {
      let dataList = sendFileData.filter((file, index) => index !== id);
      sendFileData = dataList;
      setSendFileList(dataList);
    },
    [sendFileList]
  );

  const handleDragIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragOut = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    if (!isDragging) {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer.files) {
        setIsDragging(true);
      }
    }
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onChangeFiles(e);
      setIsDragging(false);
    },
    [onChangeFiles]
  );

  const initDragEvents = useCallback(() => {
    if (chatRoomDragRef.current !== null) {
      chatRoomDragRef.current.addEventListener("dragenter", handleDragIn);
      chatRoomDragRef.current.addEventListener("dragleave", handleDragOut);
      chatRoomDragRef.current.addEventListener("dragover", handleDragOver);
      chatRoomDragRef.current.addEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const resetDragEvents = useCallback(() => {
    if (chatRoomDragRef.current !== null) {
      chatRoomDragRef.current.removeEventListener("dragenter", handleDragIn);
      chatRoomDragRef.current.removeEventListener("dragleave", handleDragOut);
      chatRoomDragRef.current.removeEventListener("dragover", handleDragOver);
      chatRoomDragRef.current.removeEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  useEffect(() => {
    initDragEvents();
    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents]);

  const openWindow = () => {
    let url = "/LogiTalkChatRoomPop/" + activeChRoom.crmMainKey;
    let form = $("form");
    let target = activeChRoom.crmMainKey;
    let data = {
      loginInfo: loginInfo,
      activeChRoom: activeChRoom,
      isMobile: isMobile,
      alertDisabledList: alertDisabledList,
      delActiveChRoom: delActiveChRoom,
      changedActiveMainMenu: changedActiveMainMenu,
      updateAlertDisable: updateAlertDisable,
      deleteChatRoom: deleteChatRoom,
      isWindowActive: isWindowActive,
      chatTitleProps: chatTitle,
    };
    //sessionStorage.setItem(activeChRoom.crmMainKey, JSON.stringify(data));
    sessionStorage.setItem("activeChRoom", JSON.stringify(data));

    window.open(
      url,
      target,
      "width=800, height=1000, resizable=yes, status=no, menubar=no, toolbar=no"
    );
  };

  //답장글 클릭시 원본 메세지로 이동 기능
  const scrollToReply = (replyMSGKey, dataList, isSelf) => {
    if (isSelf) {
      mFindReplyCount++;
      if (mFindReplyCount > 10) return;
    } else {
      mFindReplyCount = 0;
    }
    if ($(`#${replyMSGKey}`).length > 0) {
      $("#chat").scrollTop(
        $("#chat").scrollTop() + $(`#${replyMSGKey}`).position().top
      );
    } else {
      GET_TALK_MSG_LIST(
        (newData, dataList) => {
          if ($(`#${replyMSGKey}`).length > 0) {
            try {
              $("#chat").scrollTop(
                $("#chat").scrollTop() + $(`#${replyMSGKey}`).position().top
              );
            } catch (e) {
              console.log(e);
            }
          } else {
            setTimeout(() => scrollToReply(replyMSGKey, dataList, true));
          }
        },
        dataList ? { crtIDX: dataList[0].crtIDX } : null
      );
    }
  };

  const extractURL = (text) => {
    let urlRegex = /(https?:\/\/[^ ]*)/;
    let url = text.match(urlRegex)[1];
    return url;
  };

  return (
    <div
      className="h-100 w-100 white_bg border-left d-flex flex-column justify-content-between position-relative p-3"
      ref={chatRoomDragRef}
    >
      <div className="pb-3 d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row justify-content-center align-items-center flex-fill w-100">
          <div className="text-secondary flex-fill p-0 m-0 overflow-hidden">
            {chatTitleChange ? (
              <div>
                <input
                  type="text"
                  className="form-control rounded-pill mb-3"
                  placeholder={"채팅방 제목을 입력해 주세요"}
                  ref={chTitle}
                  maxLength={20}
                  onKeyPress={(e) => {
                    if (e.charCode === 13) {
                      changeChatTitle();
                    }
                  }}
                />
                <div className="d-flex flex-row justify-content-center">
                  <div
                    className="btn_1 outline mr-2"
                    onClick={() => {
                      setChatTitleChange(false);
                    }}
                  >
                    취소
                  </div>
                  <div
                    className="btn_1"
                    onClick={() => {
                      changeChatTitle();
                    }}
                  >
                    확인
                  </div>
                </div>
              </div>
            ) : (
              <h4
                className="h6 font-weight-bold m-0 px-2 text-secondary text-truncate overflow-hidden"
                style={{ maxWidth: 400 }}
              >
                {loginInfo.selfChatRoomKey === activeChRoom.crmMainKey && (
                  <span
                    className="d-inline-flex flex-row justify-content-center align-items-center bg-secondary text-white text-center rounded-circle mr-1"
                    style={{
                      width: 20,
                      height: 20,
                      fontSize: "0.8em",
                    }}
                  >
                    나
                  </span>
                )}
                {chatTitle}
                <button
                  className="btn"
                  onClick={openWindow}
                  style={{ display: "inline" }}
                >
                  <i className="fa-solid fa-arrow-up-right-from-square"></i>
                </button>
              </h4>
            )}
          </div>
        </div>
        {!chatTitleChange && (
          <ChatRoomOption
            setChUserListView={setChUserListView}
            addToChatUser={addToChatUser}
            setIsFileShow={setIsFileShow}
            activeAlert={activeAlert}
            setActiveAlert={setActiveAlert}
            updateAlertDisable={updateAlertDisable}
            activeChRoom={activeChRoom}
            setChatTitleChange={setChatTitleChange}
            isMSGSearch={isMSGSearch}
            setIsNoticeListShow={setIsNoticeListShow}
            handleSearchMsgState={handleSearchMsgState}
            EVENT_SOCKET_TALK_LEAVE_ROOM={EVENT_SOCKET_TALK_LEAVE_ROOM}
          />
        )}
        {isMobile && !chatTitleChange && (
          <h4
            className="font-weight-bold text-secondary p-2 m-0 ml-2 cursor_pointer align-self-center"
            onClick={() => {
              parentChDel();
            }}
          >
            <i className="fa-solid fa-xmark" />
          </h4>
        )}
      </div>
      {isMSGSearch && (
        <div className="d-flex flex-row  mb-3">
          <div className="flex-grow-1">
            <div className="input-group">
              <input
                type="text"
                className="form-control border-0 bg-light"
                placeholder="검색어를 입력해주세요"
                ref={msgSearchInput}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    actionSearchMsg();
                  }
                }}
              />
              <div className="input-group-append">
                <button
                  className="btn btn_1 px-4"
                  type="button"
                  onClick={() => actionSearchMsg()}
                >
                  검색
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-around">
            <button
              className={`h2 ${
                msg_search_index > 0 ||
                (msg_search_index <= 0 &&
                  msg_search_amount > msg_search_current_amount)
                  ? "text-gbts"
                  : "text-secondary"
              } d-inline-block m-0 border-0 bg-transparent`}
              onClick={() => {
                if (isLoading) return;

                if (
                  msg_search_index > 0 ||
                  (msg_search_index <= 0 &&
                    msg_search_amount > msg_search_current_amount)
                ) {
                  moveUpSearchMsg();
                } else {
                  codeList.Modal.current.alert(
                    "메세지검색의 마지막 메세지입니다."
                  );
                }
              }}
            >
              {isLoading ? (
                <span className="h4 m-0">
                  <i className="fa-solid fa-spinner rotate" />
                </span>
              ) : (
                <i className="fa-solid fa-angle-up" />
              )}
            </button>

            <button
              className={`h2 ${
                msg_search_amount > 0 &&
                msg_search_index < msg_search_items.length - 1
                  ? "text-gbts"
                  : "text-secondary"
              }  d-inline-block m-0 border-0 bg-transparent`}
              onClick={() => {
                if (
                  msg_search_amount > 0 &&
                  msg_search_index < msg_search_items.length - 1
                ) {
                  moveDownSearchMsg();
                }
              }}
            >
              <i className="fa-solid fa-angle-down" />
            </button>
          </div>
        </div>
      )}
      {chUserListView && (
        <ChatRoomUserList
          setChUserListView={setChUserListView}
          chUserData={chUserData}
        />
      )}
      <input
        type="file"
        id="fileUpload"
        style={{ display: "none" }}
        multiple={true}
        onChange={onChangeFiles.bind(this)}
      />{" "}
      {isDragging && <DraggingCover />}
      <div
        id="chat"
        className={`p-3 flex-fill position-relative bg-blue-light  rounded-xl-top ${
          isFileShow ? "overflow-hidden" : "overflow_scroll scroll_hidden"
        } ${chatSmoothScroll ? "" : "noneSmoothScroll"} `}
        style={{ height: "100px" }}
        onScroll={() => {
          scrollBtnHandler();
        }}
      >
        {current_notice.hasOwnProperty("crnIDX") && (
          <div
            className={isNoticeShow ? "" : "d-none"}
            style={{ zIndex: 99999 }}
          >
            <LogiTalkChatRoomNotice
              current_notice={current_notice}
              setIsNoticeListShow={setIsNoticeListShow}
            />
          </div>
        )}
        <>
          {msgListFilter.hasOwnProperty("crtIDX") && !msgEnd && (
            <GetBeforeMSGButton GET_TALK_MSG_LIST={GET_TALK_MSG_LIST} />
          )}
          {msgEnd && <EndMSG />}
          {!isAllow &&
            chUserData.hasOwnProperty(
              `${loginInfo.comp_cd}_${loginInfo.user_id}`
            ) && (
              <AllowedMsg
                chUserData={chUserData}
                activeChRoom={activeChRoom}
                TALK_ALLOW={TALK_ALLOW}
              />
            )}

          {chList.length > 0 &&
          chUserData.hasOwnProperty(`${loginInfo.comp_cd}_${loginInfo.user_id}`)
            ? chList.map((ch, index) => {
                let sameBefore = false;
                if (index > 0) {
                  if (
                    ch.senderUserID === chList[index - 1].senderUserID &&
                    ch.senderUserNICK === chList[index - 1].senderUserNICK
                  ) {
                    if (Number(chList[index - 1].crtType) !== 3) {
                      sameBefore = true;
                    }
                  }
                }

                let loginUserMsg =
                  loginInfo.user_id === ch.senderUserID &&
                  loginInfo.comp_cd === ch.senderCompCD;

                let chDateData = new Date(ch.crtCreatDate);

                if (Number(ch.crtType) === 3) {
                  return (
                    <div
                      key={`${ch.crtMSGKey}_${index}`}
                      className="bg-white rounded-lg p-3 text-center font-weight-bold mb-2 px-5"
                    >
                      <div className="mb-2">SYSTEM : {ch.crtMSG}</div>
                    </div>
                  );
                } else {
                  let userDataList = [];
                  for (let key in chUserData) {
                    key !== `${ch.senderCompCD}_${ch.senderUserID}` &&
                      userDataList.push(chUserData[key]);
                  }

                  let unread = userDataList.filter((element) => {
                    if (
                      element.cruUserID !== ch.senderUserID &&
                      element.USE_GB !== "D"
                    ) {
                      if (
                        !element.lastReadIDX ||
                        Number(element.lastReadIDX) < Number(ch.crtIDX)
                      ) {
                        return element;
                      }
                    }
                  });

                  unread = unread.length;
                  let senderImg = ch.senderUserPIC;

                  if (
                    chUserData.hasOwnProperty(
                      `${ch.senderCompCD}_${ch.senderUserID}`
                    )
                  ) {
                    senderImg = chUserData[
                      `${ch.senderCompCD}_${ch.senderUserID}`
                    ].UserPIC
                      ? chUserData[`${ch.senderCompCD}_${ch.senderUserID}`]
                          .UserPIC
                      : user_default_image;
                  }

                  let senderNick = ch.senderUserNICK
                    ? ch.senderUserNICK
                    : ch.senderUserName;

                  if (
                    chUserData.hasOwnProperty(
                      `${ch.senderCompCD}_${ch.senderUserID}`
                    )
                  ) {
                    senderNick = chUserData[
                      `${ch.senderCompCD}_${ch.senderUserID}`
                    ].UserNICK
                      ? chUserData[`${ch.senderCompCD}_${ch.senderUserID}`]
                          .UserNICK
                      : chUserData[`${ch.senderCompCD}_${ch.senderUserID}`]
                          .UserName;
                  }
                  let newCrtMSG = "";
                  let index = ch.crtMSG.search(
                    /[\u{1F004}-\u{1F9E6}]|[\u{1F600}-\u{1F9D0}]/gu
                  );
                  if (index > -1) {
                    newCrtMSG = ch.crtMSG;
                  }

                  let msg = ch.crtMSG;

                  if (
                    ch.crtType == "2" &&
                    (msg.indexOf("님이 파일을 전송하였습니다.") > -1 ||
                      msg.indexOf("sent the file.") > -1)
                  ) {
                    msg = "";
                  }

                  let replyUser = "";
                  let replyMSG = "";
                  let replyMSGKey = "";
                  if (ch.crtType == "5") {
                    let crtMSGSplit = msg.split("|¿|");
                    replyUser = crtMSGSplit[0];
                    replyMSG = crtMSGSplit[1];
                    replyMSGKey = crtMSGSplit[2];
                    crtMSGSplit.splice(0, 3);
                    msg = crtMSGSplit.join("");

                    if (newCrtMSG !== "") {
                      newCrtMSG = msg;
                    }
                  }

                  if (msg.indexOf("|¿|") > -1) {
                    msg = msg.split("|¿|").join("");
                  }

                  let isSearchItem = false;
                  let isSearchFocus = false;

                  if (
                    isMSGSearch &&
                    msg_search_word !== "" &&
                    msg.indexOf(msg_search_word) > -1
                  ) {
                    isSearchItem = true;
                    if (
                      msg_search_items.length > 0 &&
                      msg_search_items[msg_search_index].hasOwnProperty(
                        "crtMSGKey"
                      )
                    ) {
                      if (
                        msg_search_items[msg_search_index].crtMSGKey ===
                        ch.crtMSGKey
                      ) {
                        isSearchFocus = true;
                      }
                    }
                  }

                  if (
                    typeof ch.crtFile === "object" &&
                    Array.isArray(ch.crtFile)
                  ) {
                    ch.crtFile = ch.crtFile.join("|");
                  }

                  return (
                    <div id={ch.crtMSGKey} key={ch.crtMSGKey}>
                      {dateSeparator(ch.crtCreatDate)}
                      {!sameBefore && !loginUserMsg && (
                        <div className="d-flex flex-row justify-content-start align-items-center ">
                          <div
                            className="overflow-hidden border rounded-circle mr-2"
                            style={{
                              background: `url(${senderImg}) 50% 50%/cover no-repeat`,
                              width: 45,
                              height: 45,
                            }}
                          />
                          <h6 className="font-weight-bold m-0 p-0">
                            {senderNick}
                          </h6>
                        </div>
                      )}

                      <div
                        className={`d-flex flex-row py-2 ${
                          loginUserMsg
                            ? "justify-content-end pl-5"
                            : "justify-content-start"
                        } align-items-end`}
                      >
                        <span
                          className={`text-secondary d-flex flex-row  ${
                            loginUserMsg ? "mr-2" : "ml-2 order-1"
                          } `}
                          style={{ fontSize: "0.6em" }}
                        >
                          {loginUserMsg && ch.crtDelYN === "N" && (
                            <span
                              className="mr-2 font-weight-bold text-danger cursor_pointer"
                              onClick={() => {
                                EVENT_SOCKET_MSG_DELETE(ch);
                              }}
                            >
                              삭제
                            </span>
                          )}
                          {!loginUserMsg && (
                            <div
                              className="ml-2 font-weight-bold d-inline-block cursor_pointer order-3"
                              style={{ color: "#3B44AC" }}
                              onClick={() => {
                                let crtMSGTruncated = ch.crtMSG;
                                let crtMSGSplit = crtMSGTruncated.split("|¿|");
                                if (crtMSGSplit.length > 3) {
                                  crtMSGSplit.splice(0, 3);
                                  crtMSGTruncated = crtMSGSplit.join("|¿|");
                                }

                                if (crtMSGTruncated.length > 20) {
                                  crtMSGTruncated =
                                    crtMSGTruncated.substr(0, 20) + "...";
                                  crtMSGTruncated = crtMSGTruncated;
                                }
                                setReplyData({
                                  senderUserNICK: ch.senderUserNICK,
                                  senderUserName: ch.senderUserName,
                                  crtMSG: crtMSGTruncated,
                                  crtMSGKey: ch.crtMSGKey,
                                });
                              }}
                            >
                              답장
                            </div>
                          )}
                          {unread > 0 ? (
                            <span
                              className={`text-danger font-weight-bold ${
                                loginUserMsg ? "mr-2" : "ml-2 order-1"
                              } `}
                            >
                              {unread}
                            </span>
                          ) : (
                            ""
                          )}
                          <span>
                            {delTimeSec(chDateData.toLocaleTimeString())}
                          </span>
                        </span>

                        {!loginUserMsg && (
                          <div style={{ width: 45 }} className="mr-2" />
                        )}
                        {ch.crtDelYN === "Y" ? (
                          <div
                            className={`px-3 py-2 ${
                              loginUserMsg ? "text-white" : ""
                            } d-inline-block ${
                              isSearchFocus ? "border border-bottom-danger" : ""
                            }`}
                            style={{
                              borderRadius: loginUserMsg
                                ? "20px 0px 20px 20px"
                                : "0px 20px 20px 20px",
                              background: isSearchItem
                                ? "#ffc107"
                                : loginUserMsg
                                ? "#3B44AC"
                                : "#fff",
                              maxWidth: 600,
                              wordBreak: "break-word",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            삭제된 메세지입니다.
                          </div>
                        ) : (
                          <div
                            className={`shadow-sm ${
                              loginUserMsg ? "text-white" : ""
                            } d-inline-block ${
                              isSearchFocus ? "border border-danger" : ""
                            }`}
                            style={{
                              borderWidth: isSearchFocus ? "3px" : "0",
                              borderRadius: loginUserMsg
                                ? "20px 0px 20px 20px"
                                : "0px 20px 20px 20px",
                              background: isSearchItem
                                ? "#ffc107"
                                : loginUserMsg
                                ? "#3B44AC"
                                : "#fff",
                              maxWidth: 600,
                              wordBreak: "break-word",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            <div
                              className={`border-bottom px-3 py-2 cursor_pointer ${
                                replyUser ? "" : "d-none"
                              }`}
                              onClick={() => {
                                scrollToReply(replyMSGKey);
                              }}
                            >
                              <p className="font-weight-bold ">
                                {replyUser}님에게 답장
                              </p>
                              <p>{replyMSG}</p>
                            </div>
                            <div
                              className={`px-3 ${
                                ch.crtFile && ch.crtFile.trim() !== ""
                                  ? msg !== ""
                                    ? "pt-2"
                                    : ""
                                  : "py-2"
                              } msgLink ${loginUserMsg ? "linkWrap" : ""}`}
                            >
                              <Linkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <a
                                    target="blank"
                                    href={decoratedHref}
                                    key={key}
                                  >
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                {newCrtMSG !== "" ? (
                                  <span className="h5 m-0">{newCrtMSG}</span>
                                ) : (
                                  msg
                                )}
                              </Linkify>
                            </div>
                            {ch.crtFile !== "" &&
                              FileMsg(
                                ch.crtFile,
                                ch.senderUserID === loginInfo.user_id &&
                                  ch.senderCompCD === loginInfo.comp_cd
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              })
            : ""}
        </>
      </div>
      <div className="d-flex flex-column justify-content-center p-3 mt-auto position-relative bg-blue-light mb-3 rounded-xl-bottom">
        {isScroll && newMsg !== "" ? (
          <ScrollNewMSG moveToChatScroll={moveToChatScroll} newMsg={newMsg} />
        ) : (
          ""
        )}

        {replyData !== null && (
          <div className="bg-white mb-2 p-3 rounded d-flex flex-row justify-content-between">
            <div className="d-inline-block">
              <p className="font-weight-bold">
                {replyData.senderUserNICK
                  ? replyData.senderUserNICK
                  : replyData.senderUserName}
                님에게 답장
              </p>
              <p>{replyData.crtMSG}</p>
            </div>
            <div className="d-inline-block">
              <span
                className="font-weight-bold cursor_pointer text-danger"
                onClick={() => {
                  setReplyData(null);
                }}
              >
                취소
              </span>
            </div>
          </div>
        )}

        {sendFileList.length > 0 && (
          <SendFileList
            sendFileList={sendFileList}
            setSendFileList={setSendFileList}
            handleFilterFile={handleFilterFile}
          />
        )}
        {scrollBtn && !newMsg && (
          <div
            className="position-absolute text-white rounded-circle d-flex flex-row justify-content-center align-items-center cursor_pointer"
            style={{
              top: -40,
              right: 10,
              width: 30,
              height: 30,
              background: "rgba(0,0,0,0.5)",
            }}
            onClick={() => {
              moveToChatScroll();
            }}
          >
            <i className="fas fa-arrow-down" />
          </div>
        )}
        <div className="d-flex">
          <input
            type="file"
            className="custom-file-input d-none"
            id="chatFiles"
            multiple={true}
            onChange={onChangeFiles.bind(this)}
            disabled={!isAllow}
          />
          <label
            htmlFor="chatFiles"
            className="m-0 pr-2 d-flex flex-column justify-content-center cursor_pointer"
          >
            <i className="far fa-plus-square h4 m-0 text-secondary" />
          </label>

          <textarea
            rows="1"
            className="form-control flex-fill overflow_scroll-Y autosize"
            style={{ borderRadius: "1rem" }}
            disabled={isAllow ? isDisabledChat : true}
            placeholder={
              isAllow
                ? isDisabledChat
                  ? "탈퇴한 회원으로 채팅이 불가합니다."
                  : ""
                : isDisabledChat
                ? "탈퇴한 회원으로 채팅이 불가합니다."
                : "요청을 수락해야만 대화가 가능합니다."
            }
            ref={msgInput}
            onKeyUp={(e) => {
              let textArea = $("textarea.autosize");
              textArea.height(1).height(textArea.prop("scrollHeight") - 13);
              //resize(this);
            }}
            onKeyDown={() => {
              let textArea = $("textarea.autosize");
              textArea.height(1).height(textArea.prop("scrollHeight") - 13);
            }}
            onKeyPress={(e) => {
              if (e.charCode === 13 && e.shiftKey === false) {
                TALK_MSG_SEND(msgInput.current.value.trim());
                msgInput.current.value = "";
                e.preventDefault();
              }
            }}
          />
          <InputEmoji
            value={text}
            onChange={(e) => {
              msgInput.current.value = msgInput.current.value + e;
              $(".react-input-emoji--input")[0].innerText = "";
              setText("");
            }}
            disabled={!isAllow}
            ref={emgInput}
            cleanOnEnter
          />
          <div
            className="m-0 d-flex flex-column justify-content-center cursor_pointer h4  text-secondary"
            onClick={() => {
              TALK_MSG_SEND(msgInput.current.value.trim());
              msgInput.current.value = "";
            }}
          >
            <i className="far fa-paper-plane" />
          </div>
        </div>
      </div>
      {isNoticeListShow && (
        <LogiTalkChatRoomNoticeList
          notice_list={notice_list}
          setIsNoticeListShow={setIsNoticeListShow}
          activeChRoom={activeChRoom}
          loginInfo={loginInfo}
          user_list={user_list}
        />
      )}
      {isFileShow && (
        <ChatRoomFiles
          activeChRoom={activeChRoom}
          isAllow={isAllow}
          setIsFileShow={setIsFileShow}
        />
      )}
    </div>
  );
}

//----------------------------------------------------------------------------

//채팅방 내 메뉴
function ChatRoomOption(props) {
  let {
    setChUserListView,
    addToChatUser,
    setIsFileShow,
    activeAlert,
    setActiveAlert,
    updateAlertDisable,
    activeChRoom,
    setChatTitleChange,
    isMSGSearch,
    handleSearchMsgState,
    setIsNoticeListShow,
    EVENT_SOCKET_TALK_LEAVE_ROOM,
  } = props;
  return (
    <div className="d-flex flex-row align-items-center">
      <div
        className={`nav-item dropdown text-secondary pr-2 position-relative`}
      >
        <i
          className="fa-solid fa-bars nav-link dropdown-toggle text-secondary"
          id="chat-dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-expanded="false"
        />
        <div className="dropdown-menu text-secondary custom_dropdown border-0 shadow">
          <div
            className="dropdown-item p-1 px-3 cursor_pointer d-flex flex-row justify-content-start align-items-center text-secondary"
            onClick={() => {
              setChUserListView(true);
            }}
          >
            <i className="fas fa-users mr-2" style={{ minWidth: 20 }} />
            <div>대화 상대 보기</div>
          </div>
          <div
            className="dropdown-item p-1 px-3 cursor_pointer d-flex flex-row justify-content-start align-items-center text-secondary"
            onClick={() => {
              addToChatUser();
            }}
          >
            <i className="fas fa-user-plus mr-2" style={{ minWidth: 20 }} />
            <div>대화 상대 추가</div>
          </div>
          <div
            className="dropdown-item p-1 px-3 cursor_pointer d-flex flex-row justify-content-start align-items-center text-secondary"
            onClick={() => {
              setIsFileShow(true);
            }}
          >
            <i
              className="fa-solid fa-box-archive mr-2"
              style={{ minWidth: 20 }}
            />
            <div>파일</div>
          </div>
          <div
            className="dropdown-item p-1 px-3 cursor_pointer d-flex flex-row justify-content-start align-items-center text-secondary"
            onClick={() => {
              setIsNoticeListShow(true);
            }}
          >
            <i className="fa-solid fa-bullhorn mr-2" style={{ minWidth: 20 }} />
            <div>공지</div>
          </div>
          {activeAlert ? (
            <div
              className="dropdown-item p-1 px-3 cursor_pointer d-flex flex-row justify-content-start align-items-center text-secondary"
              onClick={() => {
                setActiveAlert(false);
                updateAlertDisable("INSERT", activeChRoom);
              }}
            >
              <i className="fas fa-bell mr-2" style={{ minWidth: 20 }} />
              <div>알림 끄기</div>
            </div>
          ) : (
            <div
              className="dropdown-item p-1 px-3 cursor_pointer d-flex flex-row justify-content-start align-items-center text-secondary"
              onClick={() => {
                setActiveAlert(true);
                updateAlertDisable("DELETE", activeChRoom);
              }}
            >
              <i className="far fa-bell-slash mr-2" style={{ minWidth: 20 }} />
              <div>알림 켜기</div>
            </div>
          )}
          <div className="dropdown-divider" />
          <div
            className="dropdown-item p-1 px-3 cursor_pointer d-flex flex-row justify-content-start align-items-center text-secondary"
            onClick={() => {
              setChatTitleChange(true);
            }}
          >
            <i className="far fa-bookmark mr-2" style={{ minWidth: 20 }} />
            <div>채팅방 이름변경</div>
          </div>

          <div
            className="dropdown-item p-1 px-3 cursor_pointer d-flex flex-row justify-content-start align-items-center text-secondary"
            onClick={() => {
              EVENT_SOCKET_TALK_LEAVE_ROOM();
            }}
          >
            <i className="fas fa-sign-out-alt mr-2" style={{ minWidth: 20 }} />
            <div>나가기</div>
          </div>
        </div>
      </div>
      <div className="cursor_pointer" onClick={() => handleSearchMsgState()}>
        <i
          className={`fa-solid ${
            isMSGSearch ? "fa-xmark" : "fa-magnifying-glass"
          }`}
        />
      </div>
    </div>
  );
}

//채팅방 유저리스트 보기
function ChatRoomUserList(props) {
  let { setChUserListView, chUserData } = props;
  return (
    <div className="d-flex flex-row align-items-center flex-wrap pb-2">
      <div
        className="rounded-pill border bg-light p-1 px-2 d-flex flex-row justify-content-center align-items-center mr-2 cursor_pointer font-weight-bold  mt-2"
        onClick={() => {
          setChUserListView(false);
        }}
      >
        &times; 닫기
      </div>

      {Object.values(chUserData).map((chUser) => {
        return (
          <div
            className="rounded-pill border p-2 d-flex flex-row align-items-center mr-2 mt-2 position-relative"
            key={`${chUser.cruUserSite}_${chUser.cruUserID}`}
          >
            <div
              className={`position-absolute rounded-pill bg-white font-weight-bold ${
                chUser.USE_GB !== "D"
                  ? chUser.cruAgreeYN === "Y"
                    ? "text-info"
                    : "text-secondary"
                  : "text-danger"
              }`}
              style={{
                fontSize: "0.9em",
                padding: "3px",
                right: 15,
                bottom: "100%",
                transform: "translateY(55%)",
              }}
            >
              {chUser.USE_GB !== "D"
                ? chUser.cruAgreeYN === "Y"
                  ? "수락"
                  : "미수락"
                : "탈퇴"}
            </div>
            <div
              className="rounded-circle overflow-hidden border mr-1"
              style={{
                background: `url(${
                  chUser.UserPIC
                    ? chUser.UserPIC
                    : "https://www.gbts.co.kr/images/user_pic-50x50.png"
                }) 50% 50%/cover no-repeat`,
                width: 30,
                height: 30,
              }}
            />
            [
            {chUser.cruUserSite.indexOf("-") > -1 ? "GUEST" : chUser.UserCOMPNM}
            ] {chUser.UserNICK ? chUser.UserNICK : chUser.UserName}
          </div>
        );
      })}
    </div>
  );
}

//파일 드러그 커버
function DraggingCover() {
  return (
    <div
      className="drag_mask p-3 h1 w-100 h-100 d-flex flex-row justify-content-center align-items-center position-absolute text-primary"
      style={{
        background: "rgba(255,255,255,0.8)",
        backdropFilter: "blur(2px)",
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <span className="h1"> 파일업로드</span>
      <span className="h1">
        <i className="fa-solid fa-file-import" />
      </span>
    </div>
  );
}

//이전 메세지 불러오기 버튼
function GetBeforeMSGButton(props) {
  const { GET_TALK_MSG_LIST } = props;
  return (
    <div className="d-flex flex-row justify-content-center mb-2">
      <div className="btn_1 rounded-pill" onClick={() => GET_TALK_MSG_LIST()}>
        이전 대화보기
      </div>
    </div>
  );
}

//마지막 메세지 안내문
function EndMSG() {
  return (
    <div className="d-flex flex-row align-items-center">
      <div className="border border-bottom-0 flex-grow-1" />
      <div className="text-secondary  px-2">마지막 메세지 입니다.</div>
      <div className="border border-bottom-0 flex-grow-1" />
    </div>
  );
}

//초대 승인 거절
function AllowedMsg(props) {
  let { chUserData, activeChRoom, TALK_ALLOW } = props;
  return (
    <div className="bg-white rounded-lg p-3 text-center font-weight-bold mb-2">
      <div className="mb-2">
        SYSTEM :{" "}
        {chUserData.hasOwnProperty(
          `${activeChRoom.crmCreatorSite}_${activeChRoom.crmCreator}`
        )
          ? chUserData[
              `${activeChRoom.crmCreatorSite}_${activeChRoom.crmCreator}`
            ].UserNICK
            ? chUserData[
                `${activeChRoom.crmCreatorSite}_${activeChRoom.crmCreator}`
              ].UserNICK
            : chUserData[
                `${activeChRoom.crmCreatorSite}_${activeChRoom.crmCreator}`
              ].UserName
          : "[알수없음]"}{" "}
        님이 대화를 요청하였습니다.
      </div>
      <button className="btn_1 mr-2" onClick={() => TALK_ALLOW("Y")}>
        수락
      </button>
      <button className="btn_1 outline" onClick={() => TALK_ALLOW("D")}>
        거절
      </button>
    </div>
  );
}

//첨부파일메세지
function FileMsg(data, me) {
  if (!data || typeof data !== "string") return;

  let fileData = data.split("|");
  let images = [];
  let videos = [];
  let audio = [];
  let files = [];

  fileData.map((file, index) => {
    let splitData2 = file.split("?");
    if (splitData2.length !== 2) return;
    let fileName = splitData2[1];
    fileName = fileName.toLowerCase();
    if (
      fileName.endsWith(".jpg") ||
      fileName.endsWith(".jpeg") ||
      fileName.endsWith(".gif") ||
      fileName.endsWith(".bmp") ||
      fileName.endsWith(".png")
    ) {
      images.push(file);
    } else if (
      fileName.endsWith(".mov") ||
      fileName.endsWith(".mp4") ||
      fileName.endsWith(".avi") ||
      fileName.endsWith(".mkv") ||
      fileName.endsWith(".wmv") ||
      fileName.endsWith(".flv") ||
      fileName.endsWith(".mpg") ||
      fileName.endsWith(".vob") ||
      fileName.endsWith(".3gp") ||
      fileName.endsWith(".webm") ||
      fileName.endsWith(".ts")
    ) {
      videos.push(file);
    } else if (
      fileName.endsWith(".aac") ||
      fileName.endsWith(".mp1") ||
      fileName.endsWith(".mp2") ||
      fileName.endsWith(".mp3")
    ) {
      audio.push(file);
    } else {
      files.push(file);
    }
  });

  return (
    <>
      {images.length > 0 && (
        <div className="d-flex flex-row flex-wrap px-3 py-2 cursor_pointer">
          {images.map((file, index) => {
            let splitData = file.split("?");
            if (splitData.length !== 2) return;

            let fileLink = splitData[0];

            return (
              <div
                key={fileLink + "_" + index}
                className="mb-2 d-flex flex-row justify-content-start align-items-center"
              >
                <span
                  style={{ minWidth: 80, width: 80, height: 80 }}
                  className="bg-secondary d-flex d-row justify-content-center rounded overflow-hidden mt-2 mr-2"
                  onClick={() => {
                    window.open(
                      fileLink,
                      "chatImage",
                      "width=800, height=1000"
                    );
                  }}
                >
                  <div
                    className="w-100 h-100"
                    style={{
                      background: `url('${fileLink}')50% 50% / cover no-repeat`,
                    }}
                  />
                </span>
              </div>
            );
          })}
        </div>
      )}
      {audio.length > 0 && (
        <div className="d-flex flex-row flex-wrap px-3 py-2 cursor_pointer">
          {audio.map((file, index) => {
            const [fileLink, fileName] = file.split("?");
            return (
              <AudioFileMessage
                key={index}
                me={me}
                fileLink={fileLink}
                fileName={fileName}
              />
            );
          })}
        </div>
      )}
      {videos.length > 0 && (
        <div className="d-flex flex-row flex-wrap px-3 py-2 cursor_pointer">
          {videos.map((file, index) => {
            let splitData = file.split("?");
            if (splitData.length !== 2) return;

            let fileLink = splitData[0];

            return (
              <video
                width="250"
                height="240"
                controls
                key={fileLink}
                style={{ zIndex: 0 }}
              >
                <source src={fileLink} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            );
          })}
        </div>
      )}
      {files.length > 0 && (
        <div className="d-flex flex-column flex-wrap px-3 py-2 cursor_pointer">
          {files.map((file, index) => {
            let splitData = file.split("?");
            if (splitData.length !== 2) return;

            let fileLink = splitData[0];
            let fileName = splitData[1];

            let nameSplit = fileName.split(".");
            let fileType = nameSplit[nameSplit.length - 1].toLowerCase().trim();

            let iconClass = "fa-solid fa-file";

            if (fileType === "xlsx" || fileType === "xls") {
              iconClass = "fa-regular fa-file-excel";
            } else if (fileType === "docx" || fileType === "doc") {
              iconClass = "fa-regular fa-file-word";
            } else if (
              fileType === "zip" ||
              fileType === "apk" ||
              fileType === "rar" ||
              fileType === "7z" ||
              fileType === "tar"
            ) {
              iconClass = "fa-regular fa-file-zipper";
            } else if (fileType === "pptx" || fileType === "ppt") {
              iconClass = "fa-regular fa-file-powerpoint";
            } else if (fileType === "pdf") {
              iconClass = "fa-regular fa-file-pdf";
            }

            return (
              <div
                className="d-flex flex-row justify-content-start align-items-center py-1 "
                key={(fileLink + "_", index)}
              >
                <div
                  className={`${
                    me ? "bg-white" : "bg-gbts"
                  }  d-flex flex-row justify-content-center align-items-center rounded overflow-hidden mr-2 rounded-circle border`}
                  style={{ width: 50, minWidth: 50, height: 50 }}
                  onClick={() => {
                    codeList.Modal.current.confirm(
                      `${fileName}을 다운로드 하시겠습니까?`,
                      (ret) => {
                        if (ret) {
                          util.downloadAs(fileLink, fileName, (ret) => {
                            if (ret.ret === false)
                              codeList.Modal.current.alert(
                                "다운로드중 오류가 발생하였습니다. \n" + ret.msg
                              );
                          });
                        }
                      }
                    );
                  }}
                >
                  <h3
                    className={`${me ? "text-gbts" : " text-white"}  m-0 p-0`}
                  >
                    <i className={iconClass} />
                  </h3>
                </div>
                <a
                  className={`font-weight-bold cursor_pointer `}
                  style={me ? { color: "#fff" } : { color: "rgb(59, 68, 172)" }}
                >
                  <>
                    <span
                      className="m-0 mr-2 w-100"
                      onClick={() => {
                        codeList.Modal.current.confirm(
                          `${fileName}을 다운로드 하시겠습니까?`,
                          (ret) => {
                            if (ret) {
                              util.downloadAs(fileLink, fileName, (ret) => {
                                if (ret.ret === false)
                                  codeList.Modal.current.alert(
                                    "다운로드중 오류가 발생하였습니다. \n" +
                                      ret.msg
                                  );
                              });
                            }
                          }
                        );
                      }}
                    >
                      <u>{fileName}</u>
                    </span>
                  </>
                </a>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

const AudioFileMessage = React.memo((props) => {
  const { me, fileLink, fileName } = props;
  const [isPlay, setIsPlay] = useState(false);
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    const newAudio = new Audio(fileLink);
    if (newAudio) {
      newAudio.addEventListener("ended", () => {
        setIsPlay(false);
      });
      setAudio(newAudio);
    }
  }, []);

  const _play = () => {
    audio.play();
    setIsPlay(true);
  };
  const _pause = () => {
    audio.pause();
    setIsPlay(false);
  };
  return (
    <div className="d-flex flex-row justify-content-start align-items-center py-1">
      <div
        className={`${
          me ? "bg-white" : "bg-gbts"
        }  d-flex flex-row justify-content-center align-items-center rounded overflow-hidden mr-2 rounded-circle border`}
        style={{ width: 50, minWidth: 50, height: 50 }}
        onClick={() => {
          console.log(isPlay);
          if (isPlay) {
            _pause();
          } else {
            _play();
          }
        }}
      >
        <h3 className={`${me ? "text-gbts" : " text-white"}  m-0 p-0`}>
          <i
            style={{ marginTop: "2px" }}
            className={`fa-regular ${
              isPlay ? "fa-circle-pause" : "fa-circle-play"
            }`}
          />
        </h3>
      </div>

      <a
        className={`font-weight-bold cursor_pointer `}
        style={me ? { color: "#fff" } : { color: "rgb(59, 68, 172)" }}
      >
        <>
          <span
            className="m-0 mr-2 w-100"
            onClick={() => {
              codeList.Modal.current.confirm(
                `${fileName}을 다운로드 하시겠습니까?`,
                (ret) => {
                  if (ret) {
                    util.downloadAs(fileLink, fileName, (ret) => {
                      if (ret.ret === false)
                        codeList.Modal.current.alert(
                          "다운로드중 오류가 발생하였습니다. \n" + ret.msg
                        );
                    });
                  }
                }
              );
            }}
          >
            <u>{fileName}</u>
          </span>
        </>
      </a>
    </div>
  );
});
/*

function AudioFileMessage(props) {
  const { me, fileLink, fileName } = props;
  const audio = new Audio(fileLink);
  const [isPlay, setIsPlay] = useState(false);
  const _play = () => {
    audio.play();
    setIsPlay(true);
  };
  const _pause = () => {
    audio.pause();
    setIsPlay(false);
  };
  return (
    <div className="d-flex flex-row justify-content-start align-items-center py-1">
      <div
        className={`${
          me ? "bg-white" : "bg-gbts"
        }  d-flex flex-row justify-content-center align-items-center rounded overflow-hidden mr-2 rounded-circle border`}
        style={{ width: 50, minWidth: 50, height: 50 }}
        onClick={() => {
          console.log(isPlay);
          if (isPlay) {
            _pause();
          } else {
            _play();
          }
        }}
      >
        <h3 className={`${me ? "text-gbts" : " text-white"}  m-0 p-0`}>
          <i
            className={`fa-regular ${
              isPlay ? "fa-circle-pause" : "fa-circle-play"
            }`}
          />
        </h3>
      </div>

      <a
        className={`font-weight-bold cursor_pointer `}
        style={me ? { color: "#fff" } : { color: "rgb(59, 68, 172)" }}
      >
        <>
          <span
            className="m-0 mr-2 w-100"
            onClick={() => {
              codeList.Modal.current.confirm(
                `${fileName}을 다운로드 하시겠습니까?`,
                (ret) => {
                  if (ret) {
                    util.downloadAs(fileLink, fileName, (ret) => {
                      if (ret.ret === false)
                        codeList.Modal.current.alert(
                          "다운로드중 오류가 발생하였습니다. \n" + ret.msg
                        );
                    });
                  }
                }
              );
            }}
          >
            <u>{fileName}</u>
          </span>
        </>
      </a>
    </div>
  );
}

*/

//스크롤 상태 새 메세지
function ScrollNewMSG(props) {
  const { newMsg, moveToChatScroll } = props;
  return (
    <div
      className="p-2 position-absolute w-100"
      style={{ bottom: "100%", left: 0 }}
    >
      <div
        className="rounded-pill w-100 p-2 px-3 shadow-sm font-weight-bold d-flex flex-row justify-content-between text-white"
        style={{
          background: "rgba(59, 68, 172,0.7)",
        }}
        onClick={() => moveToChatScroll()}
      >
        <div
          className="flex-grow-1"
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {newMsg.senderUserName} : {newMsg.crtMSG}
        </div>
        <div className="cursor_pointer pl-5">
          <i className="fas fa-arrow-down" />
        </div>
      </div>
    </div>
  );
}

//전송 파일 리스트
function SendFileList(props) {
  let { sendFileList, setSendFileList, handleFilterFile } = props;
  return (
    <div
      className="DragDrop p-0m-0 w-100 overflow_scroll"
      style={{ maxHeight: 300 }}
    >
      <div className="DragDrop-Files d-flex flex-row flex-wrap s">
        <div
          className="rounded mr-2 mb-2 font-weight-bold px-3 text-white bg-secondary py-1 d-flex flex-row justify-content-between align-items-center cursor_pointer"
          onClick={() => {
            sendFileData = [];
            setSendFileList([]);
          }}
        >
          <div className="flex-fill overflow-hidden text-truncate font-weight-bold">
            취소
          </div>
        </div>
        {sendFileList.map((file, index) => {
          let splitData = file.split("?");
          if (splitData.length === -1) return;
          let fileLink = splitData[0];
          let fileName = splitData[1];
          let isImg = false;
          if (
            fileName.endsWith(".jpg") ||
            fileName.endsWith(".jpeg") ||
            fileName.endsWith(".gif") ||
            fileName.endsWith(".bmp") ||
            fileName.endsWith(".png")
          ) {
            isImg = true;
          }

          let nameSplit = fileName.split(".");
          let iconClass = "fa-solid fa-file";
          if (!isImg) {
            let fileType = nameSplit[nameSplit.length - 1].toLowerCase().trim();
            if (fileType === "xlsx" || fileType === "xls") {
              iconClass = "fa-regular fa-file-excel";
            } else if (fileType === "docx" || fileType === "doc") {
              iconClass = "fa-regular fa-file-word";
            } else if (
              fileType === "zip" ||
              fileType === "apk" ||
              fileType === "rar" ||
              fileType === "7z" ||
              fileType === "tar"
            ) {
              iconClass = "fa-regular fa-file-zipper";
            } else if (fileType === "pptx" || fileType === "ppt") {
              iconClass = "fa-regular fa-file-powerpoint";
            } else if (fileType === "pdf") {
              iconClass = "fa-regular fa-file-pdf";
            }
          }
          return (
            <div
              className="rounded mr-2 mb-2 p-2 border bg-light font-weight-bold text-secondary d-flex flex-column justify-content-between"
              key={fileLink}
            >
              <div className="d-flex flex-row justify-content-between">
                {isImg ? (
                  <a href={fileLink} target={"_blank"}>
                    <div
                      className="rounded overflow-hidden border"
                      style={{
                        background: `url(${fileLink})50% 50%/cover no-repeat`,
                        width: 50,
                        height: 50,
                      }}
                    />
                  </a>
                ) : (
                  <div className="h1 m-0 p-0">
                    <i className={iconClass} />
                  </div>
                )}
                <div>
                  <span
                    className="DragDrop-Files-Filter"
                    onClick={() => handleFilterFile(index)}
                  >
                    X
                  </span>
                </div>
              </div>

              <div
                className="overflow-hidden text-truncate mt-1"
                style={{ maxWidth: 200, textOverflow: "ellipsis" }}
              >
                {fileName}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
