/* eslint-disable no-unused-vars */
import mAesCtr from "./aes/AesCtr";
import axios from "axios";
import Compress from "react-image-file-resizer";
//import CryptoJS from "./aes/AesGBTS";
import CryptoJS from "crypto-js";

class Utilities {
  constructor(data) {
    if (Utilities.exists) {
      return Utilities.instance;
    }
    Utilities.instance = this;
    Utilities.exists = true;
    return this;
  }
}

Utilities.prototype.appConsole = (data) => {
  if (typeof data === "string") {
    data = { text: data };
  }
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ ...data, isConsole: "" })
  );
};

Utilities.prototype.isNumber = (s) => {
  s += ""; // 문자열로 변환
  s = s.replace(/^\s*|\s*$/g, ""); // 좌우 공백 제거
  if (s == "" || isNaN(s)) return false;
  return true;
};

Utilities.prototype.arraySort = (dataList = [], key = "", desc = true) => {
  dataList.sort(function (a, b) {
    a = new Date(a[key]);
    b = new Date(b[key]);
    if (desc) {
      return a > b ? -1 : a > b ? 1 : 0;
    }
    return a < b ? -1 : a < b ? 1 : 0;
  });

  return dataList;
};

Utilities.prototype.getPercent = (num, baseNum) => {
  let per = Math.round((num / baseNum - 1) * 100);
  return per;
};

Utilities.prototype.openPopUp = (url, target = "_blank", width = 1200) => {
  const height = width * 0.75;
  const option = `width=${width},height=${height}`;
  window.open(url, target, option);
  return;
};

Utilities.prototype.handleGetDate = (date) => {
  let day = new Date(date).getDay();

  switch (day) {
    case 0:
      day = "Sun";
      break;

    case 1:
      day = "Mon";
      break;

    case 2:
      day = "Tue";
      break;

    case 3:
      day = "Wed";
      break;

    case 4:
      day = "Thu";
      break;

    case 5:
      day = "Fri";
      break;

    case 6:
      day = "Sat";
      break;

    default:
      day = "";
      break;
  }
  return day;
};

Utilities.prototype.checkCompGB = (data) => {
  if (!data) return false;

  if (data.comp_gb === "Z") {
    return true;
  } else {
    return data.comp_gb === "B" && data.COMP_GB_SUB2 === "B01";
  }
};

Utilities.prototype.changeURIForThumbnail = (URI) => {
  try {
    if (/(\.gif|\.jpg|\.jpeg|\.png|\.bmp)$/i.test(URI)) {
      URI = URI.replace(
        "https://gbts-invoice.",
        "https://gbts-invoice-resized."
      );
      URI = URI.replace(
        "s3.ap-northeast-2.amazonaws.com/gbts-invoice/",
        "s3.ap-northeast-2.amazonaws.com/gbts-invoice-resized/"
      );
    }

    return URI;
  } catch (e) {
    return URI;
  }
};

/*Utilities.prototype.downloadAs = (url, name, callback) => {
  //"Content-Type": "application/octet-stream",
  let encode = encodeURI(url);
  axios
    .get(encode, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      method: "GET",
      responseType: "blob",
    })
    .then((response) => {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = name;
      a.click();
      if (callback) callback({ ret: true, msg: "" });
    })
    .catch((err) => {
      console.log("error", err);
      if (callback) callback({ ret: false, msg: err.message });
    });
};*/

//컴퓨터 사양에 따라 큰 파일인 경우 문제가 발생하여 아래 코드로 대응
Utilities.prototype.downloadAs = async (url, name, callback) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // 파일을 Blob으로 변환
    const blob = await response.blob();

    // 파일 다운로드
    const a = document.createElement("a");
    const objectUrl = window.URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(objectUrl);

    if (callback) callback({ ret: true, msg: "Download completed." });
  } catch (err) {
    console.error("Error during file download:", err);
    if (callback) callback({ ret: false, msg: err.message });
  }
};

/*Utilities.prototype.downloadImageAs = (url, name, callback) => {
  //"Content-Type": "application/octet-stream",
  axios
    .get(url, {
      method: "GET",
      responseType: "arraybuffer",
      withCredentials: true,
    })
    .then((response) => {
      const image = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      return `data:${response.headers[
        "content-type"
      ].toLowerCase()};base64,${image}`;
      if (callback) callback({ ret: true, msg: "" });
    })
    .catch((err) => {
      console.log("error", err);
      if (callback) callback({ ret: false, msg: err.message });
    });
};*/

//컴퓨터 사양에 따라 큰 파일인 경우 문제가 발생하여 아래 코드로 대응
//Base64없이 처리
Utilities.prototype.downloadImageAs = async (url, name, callback) => {
  try {
    // fetch API로 이미지 요청
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Blob 생성
    const blob = await response.blob();

    // 다운로드 링크 생성
    const a = document.createElement("a");
    const objectUrl = window.URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(objectUrl);

    if (callback)
      callback({ ret: true, msg: "Image downloaded successfully." });
  } catch (err) {
    console.error("Error downloading image:", err);
    if (callback) callback({ ret: false, msg: err.message });
  }
};

//컴퓨터 사양에 따라 큰 파일인 경우 문제가 발생하여 아래 코드로 대응
//Base64로 변환하여 처리(필요할 경우에)
Utilities.prototype.downloadWithBase64ImageAs = async (url, name, callback) => {
  try {
    // fetch API로 이미지 요청
    const response = await fetch(url, {
      method: "GET",
      credentials: "include", // 쿠키를 포함하려면 추가
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Blob 객체 생성
    const blob = await response.blob();

    // Blob을 Base64로 변환
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result;

      if (callback) {
        callback({ ret: true, msg: "", dataUrl: base64data });
      }
    };
    reader.readAsDataURL(blob); // Blob을 Base64 데이터 URL로 변환
  } catch (err) {
    console.error("Error downloading image:", err);
    if (callback) callback({ ret: false, msg: err.message });
  }
};

Utilities.prototype.setLocalTimeString = (date) => {
  if (!date) return;
  if (typeof date === "string") {
    let retDate = date;
    retDate = new Date(retDate);
    if (retDate instanceof Date && !isNaN(retDate)) {
      retDate = retDate.toLocaleTimeString();
      return retDate;
    } else {
      // console.log("setLocalTimeString date sting type err");
      return date;
    }
  } else {
    if (date instanceof Date && !isNaN(date)) {
      return date.toLocaleTimeString();
    } else {
      // console.log("setLocalTimeString date object type err");
      return date;
    }
  }
};

Utilities.prototype.smoothScroll = (targetQuerySelector, moveValue) => {
  if (!moveValue && !targetQuerySelector) return;
  document
    .querySelector(targetQuerySelector)
    .scrollTo({ top: moveValue, behavior: "smooth" });
};

Utilities.prototype.setDate = (date) => {
  let setYear = date.getFullYear();
  let setMonth = date.getMonth() + 1;
  setMonth = setMonth < 10 ? "0" + setMonth : setMonth;
  let setDay = date.getDate();
  setDay = setDay < 10 ? "0" + setDay : setDay;
  let setDate = `${setYear}-${setMonth}-${setDay}`;
  return setDate;
};

Utilities.prototype.handleFileOnChange = (event, callback) => {
  // event.preventDefault();
  let imageType = event.target.name;
  //파일을 한 개만 입력받는 경우이기 때문에 files[0] 으로 접근해야 업로드한 파일값을 얻어올 수 있다.
  if (event.target.files.length === 0) return;
  //다중이미지
  if (event.target.files.length > 1) {
    let files = event.target.files;
    let filesArr = Array.prototype.slice.call(files);
    let setFiles = [];
    filesArr.forEach((file) => {
      if (!file.type.match("image.*")) {
        alert("확장자는 이미지 확장자만 가능합니다.");
        return;
      }
      setFiles.push(file);
      let reader = new FileReader();
      let img;
      let fileType;
      reader.onload = () => {
        Compress.imageFileResizer(
          //이미지 리사이징 Api를 실행
          file, //이미지 파일 경로
          1000, //이미지 최대 너비
          1000, //이미지 최대 높이
          "JPEG", //저장되는 확장자명
          100, //0에서 100 사이, jpeg 압축에 사용, 압축이 필요하지않다면 100으로 설정
          0, //시계 방향 회전 각도 90도의 배수로 제한, 회전이 필요하지않다면 0, (90,180,270,360)
          // URI 콜백 기능, 크기가 조정 된 이미지의 base64 형식 URI 를 반환
          (uri) => {
            img = uri;
            //확장자를 가져 오기위한 split , reverse (사용 자르고 반전 후 첫번쨰)
            fileType = file.name.split(".").reverse()[0];
            /*         this.setState(
                                  {
                                    [dataName]: img,
                                  },
                                  () => this.changeActiveNextPage()
                                );*/
            callback(imageType, img, fileType);
          },
          "base64", // base64,blob,file 중 하나 default 는 base64
          200, // 크기가 조정 된 새 이미지의 최소 width
          100 //크기가 조정 된 새 이미지의 최소 height
        );
      };
      reader.readAsDataURL(file);
    });
  } else {
    //1개 이미지
    let reader = new FileReader();
    let file = event.target.files[0];
    let img;
    let fileType;
    reader.onloadend = () => {
      //onChang 이벤트를 통해 들어온파일의 읽기 동작이 끝났을때
      Compress.imageFileResizer(
        //이미지 리사이징 Api를 실행
        file, //이미지 파일 경로
        1000, //이미지 최대 너비
        1000, //이미지 최대 높이
        "JPEG", //저장되는 확장자명
        100, //0에서 100 사이, jpeg 압축에 사용, 압축이 필요하지않다면 100으로 설정
        0, //시계 방향 회전 각도 90도의 배수로 제한, 회전이 필요하지않다면 0, (90,180,270,360)
        // URI 콜백 기능, 크기가 조정 된 이미지의 base64 형식 URI 를 반환
        (uri) => {
          img = uri;
          //확장자를 가져 오기위한 split , reverse (사용 자르고 반전 후 첫번쨰)
          fileType = file.name.split(".").reverse()[0];
          /*         this.setState(
                              {
                                [dataName]: img,
                              },
                              () => this.changeActiveNextPage()
                            );*/
          callback(imageType, img, fileType);
        },
        "base64", // base64,blob,file 중 하나 default 는 base64
        200, // 크기가 조정 된 새 이미지의 최소 width
        100 //크기가 조정 된 새 이미지의 최소 height
      );
    };
    reader.readAsDataURL(file);
  }
};

Utilities.prototype.compressHandler = (img, fileType) => {
  const formData = new FormData();
  let blobObject = Utilities.instance.blobCreationFromURL(img);
  formData.append("img", blobObject);
  formData.append("fileType", fileType);

  return axios.post(
    `https://msgserver.buddible.com:4000/upload?fileType=${fileType}`,
    formData
  );
};

Utilities.prototype.blobCreationFromURL = (dataURI) => {
  // Create Blob file from URL
  // base24 로 변환된 image file 을 blob 으로 변경 해주면서 조정된 사이즈, 용량으로 서버에 저장 시켜주는 기능
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  let byteString = atob(dataURI.split(",")[1]);
  // separate out the mime components
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  let ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
};

Utilities.prototype.addCommas = (num) => {
  //숫자에 콤마처리 (금액표시에 사용)
  if (num === null || num === undefined) return "0";
  const [int, dec] = (num + "").split(".");
  return (
    int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (dec ? "." + dec : "")
  );
};

Utilities.prototype.CheckMaxString = (obj, maxNum) => {
  /*
      일정한 글자수 이상 넘어가면 '...' 처리
       */
  let li_str_len = obj.length;
  let li_byte = 0;
  let li_len = 0;
  let ls_one_char = "";
  let ls_str2 = "";
  for (let j = 0; j < li_str_len; j++) {
    ls_one_char = obj.charAt(j);
    if (escape(ls_one_char).length > 4) {
      li_byte += 2;
    } else {
      li_byte++;
    }
    if (li_byte <= maxNum) {
      li_len = j + 1;
    }
  }
  if (li_byte > maxNum) {
    ls_str2 = obj.substr(0, li_len) + "...";
  } else {
    ls_str2 = obj;
  }
  return ls_str2;
};

Utilities.prototype.getBrowserType = () => {
  /*사용중인 브라우저 판별
      return values
      IE11,10,9,8,7
      Chrome, Opera, StartOffice, WebTV, Firefox, Safari, Mozilla
      etc...
      * */
  let _ua = navigator.userAgent;
  //IE 11,10,9,8
  let trident = _ua.match(/Trident\/(\d.\d)/i);
  if (trident != null) {
    if (trident[1] === "7.0") return "IE" + 11;
    if (trident[1] === "6.0") return "IE" + 10;
    if (trident[1] === "5.0") return "IE" + 9;
    if (trident[1] === "4.0") return "IE" + 8;
  }
  //IE 7...
  if (navigator.appName === "Microsoft Internet Explorer") return "IE" + 7;
  //other
  let agt = _ua.toLowerCase();
  if (agt.indexOf("chrome") !== -1) return "Chrome";
  if (agt.indexOf("opera") !== -1) return "Opera";
  if (agt.indexOf("staroffice") !== -1) return "Star Office";
  if (agt.indexOf("webtv") !== -1) return "WebTV";
  if (agt.indexOf("beonex") !== -1) return "Beonex";
  if (agt.indexOf("chimera") !== -1) return "Chimera";
  if (agt.indexOf("netpositive") !== -1) return "NetPositive";
  if (agt.indexOf("phoenix") !== -1) return "Phoenix";
  if (agt.indexOf("firefox") !== -1) return "Firefox";
  if (agt.indexOf("safari") !== -1) return "Safari";
  if (agt.indexOf("skipstone") !== -1) return "SkipStone";
  if (agt.indexOf("netscape") !== -1) return "Netscape";
  if (agt.indexOf("mozilla/5.0") !== -1) return "Mozilla";
};

Utilities.prototype.makeUUIDv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

Utilities.prototype.makeUUIDv4Short = () => {
  return "xxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

Utilities.prototype.todayDate = (form) => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // 0부터 시작하므로 1더함
  let day = date.getDate();
  //1자리 숫자인 경우 2자리로 만들어 줌
  if (("" + month).length === 1) {
    month = "0" + month;
  }
  if (("" + day).length === 1) {
    day = "0" + day;
  }
  //분리자 설정
  let separator = "";
  if (form.split("-")[1] != null) {
    separator = "-";
    form = form.replace(/-/gi, "");
  } else if (form.split("/")[1] != null) {
    separator = "/";
    form = form.replace(/\//gi, "");
  } else if (form.split(".")[1] != null) {
    separator = ".";
    form = form.replace(/\./gi, "");
  } else {
    separator = "";
  }
  //폼 별 형식 설정 후 리턴
  switch (form) {
    case "YYYYMMDD":
      return "" + year + separator + month + separator + day;
    case "YYYYMM":
      return "" + year + separator + month;
    case "YYYY":
      return "" + year;
    case "YYMMDD":
      return ("" + year).substring(2) + separator + month + separator + day;
    case "YYMM":
      return ("" + year).substring(2) + separator + month;
    case "YY":
      return ("" + year).substring(2);
    default:
      return "format error";
  }
};

Utilities.prototype.todayDateTime = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // 0부터 시작하므로 1더함
  let day = date.getDate();

  let hour = date.getHours();
  let min = date.getMinutes();
  let sec = date.getSeconds();
  //1자리 숫자인 경우 2자리로 만들어 줌
  if (("" + month).length === 1) {
    month = "0" + month;
  }
  if (("" + day).length === 1) {
    day = "0" + day;
  }
  if (("" + hour).length === 1) {
    hour = "0" + hour;
  }
  if (("" + min).length === 1) {
    min = "0" + min;
  }
  if (("" + sec).length === 1) {
    sec = "0" + sec;
  }

  //폼 별 형식 설정 후 리턴
  return "" + year + month + day + hour + min + sec;
};

Utilities.prototype.getDateToFormat = (d, form) => {
  let date;
  if (d === undefined) {
    return;
  } else {
    if (typeof d === "string") {
      if (Utilities.prototype.getBrowserType() === "Safari") {
        date = new Date(d.replace(/-/g, "/"));
      } else {
        date = new Date(d);
      }
    } else {
      date = new Date(d);
    }
  }

  let year = date.getFullYear();
  let month = date.getMonth() + 1; // 0부터 시작하므로 1더함
  let day = date.getDate();
  //1자리 숫자인 경우 2자리로 만들어 줌
  if (("" + month).length === 1) {
    month = "0" + month;
  }
  if (("" + day).length === 1) {
    day = "0" + day;
  }
  //분리자 설정
  let separator = "";
  if (form.split("-")[1] != null) {
    separator = "-";
    form = form.replace(/-/gi, "");
  } else if (form.split("/")[1] != null) {
    separator = "/";
    form = form.replace(/\//gi, "");
  } else if (form.split(".")[1] != null) {
    separator = ".";
    form = form.replace(/\./gi, "");
  } else {
    separator = "";
  }

  //폼 별 형식 설정 후 리턴
  switch (form) {
    case "YYYYMMDD":
      return "" + year + separator + month + separator + day;
    case "YYYYMM":
      return "" + year + separator + month;
    case "YYYY":
      return "" + year;
    case "YYMMDD":
      return ("" + year).substring(2) + separator + month + separator + day;
    case "YYMM":
      return ("" + year).substring(2) + separator + month;
    case "YY":
      return ("" + year).substring(2);
    default:
      return "format error";
  }
};

// Utilities.prototype.getDateToFormat = (date = new Date(), form) => {
//   let year = date.getFullYear();
//   let month = date.getMonth() + 1; // 0부터 시작하므로 1더함
//   let day = date.getDate();
//   //1자리 숫자인 경우 2자리로 만들어 줌
//   if (("" + month).length === 1) {
//     month = "0" + month;
//   }
//   if (("" + day).length === 1) {
//     day = "0" + day;
//   }
//   //분리자 설정
//   let separator = "";
//   if (form.split("-")[1] != null) {
//     separator = "-";
//     form = form.replace(/-/gi, "");
//   } else if (form.split("/")[1] != null) {
//     separator = "/";
//     form = form.replace(/\//gi, "");
//   } else if (form.split(".")[1] != null) {
//     separator = ".";
//     form = form.replace(/\./gi, "");
//   } else {
//     separator = "";
//   }
//
//   //폼 별 형식 설정 후 리턴
//   switch (form) {
//     case "YYYYMMDD":
//       return "" + year + separator + month + separator + day;
//     case "YYYYMM":
//       return "" + year + separator + month;
//     case "YYYY":
//       return "" + year;
//     case "YYMMDD":
//       return ("" + year).substring(2) + separator + month + separator + day;
//     case "YYMM":
//       return ("" + year).substring(2) + separator + month;
//     case "YY":
//       return ("" + year).substring(2);
//     default:
//       return "format error";
//   }
// };

/*GetValueOnlyNumber(값) - 값을 숫자만 반환해주는 함수*/
Utilities.prototype.GetValueOnlyNumber = function (num) {
  num = "" + num;
  if (num == null || num === "") {
    return "";
  }
  return num.replace(/[^0-9]/gi, "");
};

/*GetValueWithOutSpace(값) - 값안에 공백을 모두제거해주는 함수*/
Utilities.prototype.GetValueWithOutSpace = function (num) {
  num = "" + num;
  if (num == null || num === "") {
    return "";
  }

  return num.replace(/(\s*)/gi, "");
};

/*GetTimeFromSecond(값) - 3600입력시 01:00:00 반환되는 함수*/
Utilities.prototype.GetTimeFromSecond = function (time) {
  if (time === undefined) return "";
  time = parseInt(time);
  let second = Math.floor(time % 60);
  let minute = Math.floor((time % 3600) / 60);
  let hour = Math.floor(time / 3600);

  return (
    this.GetZeroFill(hour, 2) +
    ":" +
    this.GetZeroFill(minute, 2) +
    ":" +
    this.GetZeroFill(second, 2)
  );
};

Utilities.prototype.telFormatConvert = function (str) {
  //숫자로온 전화번호를 하이픈 추가
  str = "" + str;
  if (str === undefined) {
    return;
  }
  str = this.GetValueOnlyNumber(str);

  //02 ***-**** , ****-****
  if (str.substring(0, 2) === "02") {
    if (str.length === 9) {
      str =
        str.substring(0, 2) +
        "-" +
        str.substring(2, 5) +
        "-" +
        str.substring(5, 9);
    } else if (str.length === 10) {
      str =
        str.substring(0, 2) +
        "-" +
        str.substring(2, 6) +
        "-" +
        str.substring(6, 10);
    }
  }
  //031~033 ***-**** , ****-****
  if (str.substring(0, 2) === "03") {
    if (str.substring(2, 3) !== "0" && str.substring(2, 3) <= 3) {
      if (str.length === 10) {
        str =
          str.substring(0, 3) +
          "-" +
          str.substring(3, 6) +
          "-" +
          str.substring(6, 10);
      } else if (str.length === 11) {
        str =
          str.substring(0, 3) +
          "-" +
          str.substring(3, 7) +
          "-" +
          str.substring(7, 11);
      }
    }
  }

  //041~044 ***-**** , ****-****
  if (str.substring(0, 2) === "04") {
    if (str.substring(2, 3) !== "0" && str.substring(2, 3) <= 4) {
      if (str.length === 10) {
        str =
          str.substring(0, 3) +
          "-" +
          str.substring(3, 6) +
          "-" +
          str.substring(6, 10);
      } else if (str.length === 11) {
        str =
          str.substring(0, 3) +
          "-" +
          str.substring(3, 7) +
          "-" +
          str.substring(7, 11);
      }
    }
  }

  //051~055 ***-**** , ****-****
  if (str.substring(0, 2) === "05") {
    if (str.substring(2, 3) !== "0" && str.substring(2, 3) <= 5) {
      if (str.length === 10) {
        str =
          str.substring(0, 3) +
          "-" +
          str.substring(3, 6) +
          "-" +
          str.substring(6, 10);
      } else if (str.length === 11) {
        str =
          str.substring(0, 3) +
          "-" +
          str.substring(3, 7) +
          "-" +
          str.substring(7, 11);
      }
    }
  }

  //061~064 ***-**** , ****-****
  if (str.substring(0, 2) === "06") {
    if (str.substring(2, 3) !== "0" && str.substring(2, 3) <= 4) {
      if (str.length === 10) {
        str =
          str.substring(0, 3) +
          "-" +
          str.substring(3, 6) +
          "-" +
          str.substring(6, 10);
      } else if (str.length === 11) {
        str =
          str.substring(0, 3) +
          "-" +
          str.substring(3, 7) +
          "-" +
          str.substring(7, 11);
      }
    }
  }

  //0505 ***-****
  if (str.substring(0, 4) === "0505") {
    if (str.length === 11) {
      str =
        str.substring(0, 4) +
        "-" +
        str.substring(4, 7) +
        "-" +
        str.substring(7, 11);
    }
  }

  //070,080 ****-****
  if (str.substring(0, 1) === "0") {
    if (
      str.substring(1, 2) > 6 ||
      (str.substring(1, 2) <= 8 && str.substring(2, 3) === "0")
    ) {
      if (str.length == 11) {
        str =
          str.substring(0, 3) +
          "-" +
          str.substring(3, 7) +
          "-" +
          str.substring(7, 11);
      }
    }
  }

  //15**,16** ****-****
  if (str.substring(0, 2) === "15" || str.substring(0, 2) === "16") {
    if (str.substring(1, 2) > 4 && str.substring(1, 2) <= 7) {
      if (str.length === 8) {
        str = str.substring(0, 4) + "-" + str.substring(4, 8);
      }
    }
  }

  //010,011,017,018
  if (str.substring(0, 2) === "01") {
    if (
      str.substring(2, 3) === "1" ||
      str.substring(2, 3) === "7" ||
      str.substring(2, 3) === "8"
    ) {
      if (str.length === 10) {
        str =
          str.substring(0, 3) +
          "-" +
          str.substring(3, 6) +
          "-" +
          str.substring(6, 10);
      }
    } else if (str.substring(2, 3) === "0") {
      if (str.length === 11)
        str =
          str.substring(0, 3) +
          "-" +
          str.substring(3, 7) +
          "-" +
          str.substring(7, 11);
    }
  }

  return str;
};

//GetZeroFill(값,자리수) - 값과 자리수를 받아 값 앞에 0을 채워 반환해주는 함수
// "00GG",8 -> "000000GG" 반환 되야함

Utilities.prototype.GetZeroFill = function (num, width) {
  num = "" + num;
  if (num == null) {
    return "";
  }
  if (width == null || width === "") {
    width = 8;
  }

  num = "" + num;
  let diff = width - num.length;

  for (let i = 0; i < diff; i++) {
    num = "0" + num;
  }
  return num;
};

Utilities.prototype.buddibleEncrypt = (str) => {
  return mAesCtr.encrypt(str, "buddible2018..", 256);
};

Utilities.prototype.buddibleDecrypt = (str) => {
  if (!str || str === "") {
    return "";
  }
  return mAesCtr.decrypt(str, "buddible2018..", 256);
};

Utilities.prototype.GBTSEncrypt = (str) => {
  let crypto_key = "LOGINS";
  return CryptoJS.AES.encrypt(str, crypto_key);
};

Utilities.prototype.GBTSDecrypt = (str) => {
  if (!str || str === "") {
    return "";
  }
  let crypto_key = "LOGINS";
  return CryptoJS.AES.decrypt(str, crypto_key).toString(CryptoJS.enc.Utf8);
};

Utilities.prototype.getStrByte = function (str) {
  let p,
    len = 0;
  for (p = 0; p < str.length; p++) {
    str.charCodeAt(p) > 255 ? (len += 2) : len++;
  }
  return len;
};

Utilities.prototype.replaceAll = function (str, searchStr, replaceStr) {
  while (str.indexOf(searchStr) !== -1) {
    str = str.replace(searchStr, replaceStr);
  }

  return str;
};

Utilities.prototype.replaceEmptyVal = function (str) {
  if (str == null) {
    return "";
  }
  return str;
};

Utilities.prototype.validationPassword = function (value) {
  if (value.length < 8) {
    //alert("비밀번호는 문자, 숫자, 특수문자의 조합으로 8자리 이상으로 입력해주세요.");
    return false;
  }
  let charReg = /[A-Za-z]/gi;
  if (!charReg.test(value)) {
    //alert("비밀번호는 문자, 숫자, 특수문자의 조합으로 8자리 이상으로 입력해주세요.");
    return false;
  }
  let numberReg = /[0-9]/gi;
  if (!numberReg.test(value)) {
    //alert("비밀번호는 문자, 숫자, 특수문자의 조합으로 8자리 이상으로 입력해주세요.");
    return false;
  }

  let specialReg = /[~!@#$%^&*()=+_']/gi;
  return specialReg.test(value);
};

Utilities.prototype.validationNumber = function (value) {
  let regExp = /^[0-9]*$/;
  return regExp.test(value);
};

Utilities.prototype.validationMobileNumber = function (value) {
  //let regExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
  // let regExp = /[01]([016789])[-](\d{4}|\d{3})[-]\d{4}$/g;
  let regExp = /[01]([016789])(\d{4}|\d{3})\d{4}$/g;
  return regExp.test(value);
};

Utilities.prototype.validationEmail = function (email) {
  let regExp = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;
  return regExp.test(email);
};

Utilities.prototype.validationBizID = function (bizID) {
  bizID = bizID.replace(/[^(0-9)]/gi, "");

  if (bizID.length < 10) {
    return false;
  }

  let checkID = [1, 3, 7, 1, 3, 7, 1, 3, 5, 1];
  let tmpBizID,
    i,
    chkSum = 0,
    c2 = "",
    remanded;

  for (i = 0; i <= 7; i++) {
    chkSum += checkID[i] * bizID.charAt(i);
  }
  c2 = "0" + checkID[8] * bizID.charAt(8);
  c2 = c2.substring(c2.length - 2, c2.length);
  chkSum += Math.floor(c2.charAt(0)) + Math.floor(c2.charAt(1));
  remanded = (10 - (chkSum % 10)) % 10;

  return Math.floor(bizID.charAt(9)) === remanded;
};

Utilities.prototype.getAutoHyphenBizID = function (value) {
  let str = value.replace(/[^(0-9)]/gi, "");
  let l = str.length;
  if (l >= 10) {
    str = str.substr(0, 3) + "-" + str.substr(3, 2) + "-" + str.substr(5, 5);
  }
  return str;
};

Utilities.prototype.validateCardNumber = function (value) {
  //빈칸과 대시 제거
  let cardNumber = value.replace(/[ -]/g, "");

  //카드 번호가 유효한지 검사
  //정규식이 캡처 그룹들 중 하나에 들어있는 숫자를 캡처
  let match =
    /^(?:(94[0-9]{14})|(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/.exec(
      cardNumber
    );

  if (match) {
    //정규식 캡처 그룹과 같은 순서로 카드 종류 나열
    const types = [
      "BC",
      "Visa",
      "MasterCard",
      "Discover",
      "American Express",
      "Diners Club",
      "JCB",
    ];

    //일치되는 캡처 그룹 검색
    //일치부 배열의 0번째 요소 (전체 일치부중 첫 일치부)를 건너뜀
    for (let i = 1; i < match.length; i++) {
      if (match[i]) {
        //해당 그룹에 대한 카드 종류를 표시
        return { ret: true, value: types[i - 1] };
      }
    }
  } else {
    return { ret: false, value: "" };
  }
};

Utilities.prototype.getAutoHyphenPhone = function (value) {
  let str = value.replace(/[^(0-9)]/gi, "");
  let l = str.length;
  if (l > 2) {
    if (l > 7) {
      let k;
      str =
        str.substr(0, (k = str.substr(0, 2) === "02" ? 2 : 3)) +
        "-" +
        str.substr(k, l - 4 - k) +
        "-" +
        str.substr(l - 4);
    } else {
      str = str.substr(0, 3) + "-" + str.substr(3);
    }
  }
  return str;
};

Utilities.prototype.getRandomInt = function (min, max) {
  if (!Number.isInteger(min) || !Number.isInteger(max)) return 0;
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
};

Utilities.prototype.descendingComparator = function (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

Utilities.prototype.getComparator = function (order, orderBy) {
  return order === "desc"
    ? (a, b) => this.descendingComparator(a, b, orderBy)
    : (a, b) => -this.descendingComparator(a, b, orderBy);
};

Utilities.prototype.stableSort = function (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

Utilities.prototype.getCodeName = function (params, codeList) {
  const value = params;
  let index = 0;

  if (codeList.length === 0 || codeList.length === undefined) {
    return "";
  }
  for (let i = 0; i < codeList.length; i++) {
    if (value === codeList[i].value) {
      index = i;
      break;
    }
  }

  return `${codeList[index].text}`;
};

export default Utilities;
