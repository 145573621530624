/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LogiTalkNav from "./LogiTalkNav";
import LogiTalkUserList from "./chat/LogiTalkUserList";
import LogiTalkChatList from "./chat/LogiTalkChatList";
import LogiTalkChatRoom from "./chat/LogiTalkChatRoom";
// import LogiTalkChatRoom from "./chat/chatRoom/LogiTalkChatRoom_Amber";
import ChatNews from "./chatNews/ChatNews";
import ChatNewsRoom from "./chat/chatNewsRoom/ChatNewsRoom";
import CorpInfoReference from "./corpInfoReference/CorpInfoReference";
import SearchLogistics from "./searchLogistics/SearchLogistics";
import SearchShipper from "./searchShipper/SearchShipper";
import SetUserProfile from "./setUserProfile/SetUserProfile";
import ChatNewsCompModal from "./chatNews/ChatNewsCompModal";

import AutoLogin from "../lib/AutoLogin";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";

import "../CustomStyle.css";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const ALERT_DISABLE = {
  INSERT: "/talk/JS_talk_alert_disable_insert.php",
  DELETE: "/talk/JS_talk_alert_disable_delete.php",
};

const user_default_image = "https://www.gbts.co.kr/images/user_pic-50x50.png";
const pages = [
  {
    id: "LogiTalkUserList",
    component: (params) => {
      return <LogiTalkUserList {...params} />;
    },
    icon: "fas fa-user",
    title: "즐겨찾기",
    guide: "즐겨찾기된 대화상대 목록입니다.",
  },
  {
    id: "LogiTalkChatList",
    component: (params) => <LogiTalkChatList {...params} />,
    icon: "fas fa-comment",
    title: "대화방",
    guide: "대화목록입니다",
  },
  {
    id: "ChatNews",
    component: (params) => <ChatNews {...params} />,
    icon: "fa-regular fa-newspaper",
    title: "NEWS",
    guide: "실시간 물류소식을 전달해요",
  },
  {
    id: "CorpInfoReference",
    component: (params) => <CorpInfoReference {...params} />,
    icon: "fa-solid fa-magnifying-glass",
    title: "기업정보",
    guide: "기업의 신용정보 조회입니다",
  },
  {
    id: "SearchLogistics",
    component: (params) => <SearchLogistics params={params} />,
    icon: "fas fa-truck-moving",
    title: "물류기업",
    guide: "물류기업 검색이 가능합니다.",
  },
  {
    id: "SearchShipper",
    component: (params) => <SearchShipper {...params} />,
    icon: "fas fa-building",
    title: "화주기업",
    guide: "화주기업의 사용자검색이 가능합니다.",
  },
  {
    id: "SetUserprofile",
    component: (params) => <SetUserProfile params={params} />,
    icon: "fas fa-cog",
    title: "설정",
    guide: "프로필을 관리합니다.",
  },
];
const _mClassName = "LogiTalk";
let chatDataList = [];
let user_list = {};
let subscribeList = {};

export default function LogiTalk(props) {
  let eventFlow = {};
  let lastMsg = "";
  const [isMobile, setIsMobile] = useState(false);
  const [pagesOpen, setPagesOpen] = useState({
    LogiTalkUserList: true,
    LogiTalkChatList: false,
    ChatNews: false,
    CorpInfoReference: false,
    SearchLogistics: false,
    SearchShipper: false,
    SetUserprofile: false,
  });
  const [loginInfo, setLoginInfo] = useState(null);
  //-------------------------
  const [favUserList, setFavUserList] = useState({});
  const [newsChatList, setNewsChatList] = useState([]);
  const [chatRoomList, setChatRoomList] = useState([]);
  const [alertDisabledList, setAlertDisabledList] = useState({});
  //-------------------------
  const [activeChRoom, setActiveChRoom] = useState({});
  const [activeChRoomList, setActiveChRoomList] = useState([]);
  //-------------------------
  const [activeChatNewsRoom, setActiveChatNewsRoom] = useState(undefined);
  //-------------------------
  const [isWindowActive, setIsWindowActive] = useState(true);
  const [badgeCont, setBadgeCont] = useState(0);
  const param = useParams();

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (!n.ret) return;
        loginDataHandler(n["returnData"][0]);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      _mClassName,
      (b, n) => {
        window.location.replace("/");
        alert("로그인 후 이용 가능한 서비스 입니다");
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_TALK_LOGIN_STATE_CHANGED,
      _mClassName,
      (b, newData) => {
        if (newData) {
          if (newData["returnData"].length) {
            let dataList = { ...favUserList };
            let COMP_CD = newData["returnData"][0]["COMP_CD"];
            let USER_ID = newData["returnData"][0]["USER_ID"];
            let STATE = newData["returnData"][0]["STATE"];

            if (dataList.hasOwnProperty(COMP_CD)) {
              if (dataList[COMP_CD].userList.hasOwnProperty(USER_ID)) {
                if (dataList[COMP_CD].userList[USER_ID].login_state !== STATE) {
                  dataList[COMP_CD].userList[USER_ID].login_state = STATE;
                  setFavUserList(dataList);
                }
              }
            }
          }
        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_ROOM_CREATE,
      _mClassName,
      (b, n) => {
        if (n.ret === false) {
          if (n["returnData"].length > 0) {
            codeList.Modal.current.alert("이미 생성된 방이 존재합니다.");
            const item = chatRoomList.find(
              (item) => item.crmMainKey === n.data.crmMainKey
            );
            item && addActiveChRoom(item);
          } else {
            codeList.Modal.current.alert(
              "오류로인해 채팅방 생성이 불가합니다. 관리자에게 문의 바랍니다."
            );
          }
          return;
        }
        if (n["returnData"].length === 0) return;
        let chatData = { ...n["returnData"][0] };
        let chatUsers = [];
        let loginUserIndex = -1;
        chatData.chatUserList.forEach((user, index) => {
          chatUsers.push(
            `[${user.UserCOMPNM}] ${
              user.UserNICK ? user.UserNICK : user.UserName
            }`
          );
          if (
            user.cruUserID === loginInfo.user_id &&
            user.cruUserSite === loginInfo.comp_cd
          ) {
            loginUserIndex = index;
          }
        });

        if (loginUserIndex > -1) {
          if (chatData.chatUserList[loginUserIndex].cruRoomName) {
            chatData.chatRoomTitle =
              chatData.chatUserList[loginUserIndex].cruRoomName;
          } else {
            chatData.chatRoomTitle = chatUsers.join(",");
          }
        }
        let dataList = [chatData, ...chatRoomList];

        chatRoomListHandler(dataList);
        if (
          loginInfo.user_id === chatData.crmCreator &&
          chatData.crmCreatorSite === loginInfo.comp_cd
        ) {
          codeList.Modal.current.alert("대화요청이 완료되었습니다.");
          addActiveChRoom(chatData);
          changedActiveMainMenu("LogiTalkChatList");
          return;
        }
        handleNotifyData(chatData, n.msgID);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_TALK_ALLOW,
      _mClassName,
      (b, n) => {
        if (n["returnData"].length === 0) return;
        messageGetHandler(n);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_ROOM_INVITE,
      _mClassName,
      (b, n) => {
        if (n["returnData"].length === 0) return;
        messageGetHandler(n);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_TALK_CHAT_ROOM_TITLE_CHANGE,
      _mClassName,
      (b, n) => {
        if (n) {
          let dataList = [...chatRoomList];
          for (let i = 0; i < dataList.length; i++) {
            if (dataList[i].crmMainKey === n.cruMainKey) {
              dataList[i].chatRoomTitle = n.cruRoomName;
            }
          }
          chatDataList = [...dataList];
          setChatRoomList([...dataList]);
        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_TALK_MSG_SEND,
      _mClassName,
      (b, n) => {
        if (n.ret === false) return;
        if (n["returnData"].length === 0) return;

        messageGetHandler(n);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_MSG_DELETE,
      _mClassName,
      (b, n) => {
        if (n.ret === false) return;
        if (n["returnData"].length === 0) return;
        messageGetHandler(n);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_TALK_CHAT_READ,
      _mClassName,
      (b, n) => {
        if (n.ret === false) return;
        if (n["returnData"].length === 0) return;
        messageGetHandler(n, MsgIDList.EVENT_SOCKET_TALK_CHAT_READ);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_TALK_LEAVE_ROOM,
      _mClassName,
      (b, n) => {
        if (n.ret === false) return;
        if (n["returnData"].length === 0) return;
        if (
          n["returnData"][0].senderUserID === loginInfo.user_id &&
          n["returnData"][0].senderCompCD === loginInfo.comp_cd
        ) {
          deleteChatRoom(n["returnData"][0]);
        } else {
          messageGetHandler(n);
        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_TALK_FAV_UESR_INSERT,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n.ret && n.effect === 1) {
            requestFavUserList();
          }
        }
      }
    );

    //------------------

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_USER_PROFILE_CHANGED,
      _mClassName,
      (b, n) => {
        // USER_ID
        if (n) {
          if (n["returnData"].length) {
            favUserListProfileHandler(n["returnData"][0]);
            chatRoomUserProfileHandler(n["returnData"][0]);
            if (
              n["returnData"][0].USER_ID !== loginInfo.user_id ||
              n["returnData"][0].COMP_CD !== loginInfo.comp_cd
            )
              return;
            setLoginInfo({ ...loginInfo, ...n["returnData"][0] });
          }
        }
      }
    );

    socket.addLocalEventListener("ChangedNews", _mClassName, (b, n) => {
      changedNewsHandler(b, n);
    });

    socket.addLocalEventListener("SendNews", _mClassName, (b, n) => {
      console.log("SendNews", n);
      newsChatGetHandler(n);
    });
    socket.addLocalEventListener("SendNewsForInvited", _mClassName, (b, n) => {
      console.log("SendNewsForInvited", n);
      newsChatGetHandler(n);
    });

    socket.addLocalEventListener(
      MsgIDList.EVENT_TALK_NEWS_SUBSCRIBE_CHANGED,
      _mClassName,
      (b, n) => {
        if (!n) return;
        actionSubscribe(n);
      }
    );
  })();

  //--------
  useEffect(() => {
    $(window).on("blur focus", function (e) {
      let prevType = $(this).data("prevType");

      if (prevType != e.type) {
        //  reduce double fire issues
        switch (e.type) {
          case "blur":
            setIsWindowActive(false);

            // do work
            break;
          case "focus":
            setIsWindowActive(true);
            setBadgeCont(0);
            navigator.clearAppBadge();

            // do work
            break;
        }
      }

      $(this).data("prevType", e.type);
    });
    requestGBTSUserList();
    isMobileHandler();
    askNotificationPermission();

    window.addEventListener("resize", isMobileHandler);

    let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userdata) {
      if (userdata.ret) {
        loginDataHandler(userdata["returnData"][0]);
      }
    }

    return () => {
      window.removeEventListener("resize", isMobileHandler);
      //-----------------------------------------------------------------------------------------

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_TALK_LOGIN_STATE_CHANGED,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_ROOM_CREATE,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_TALK_ALLOW,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_ROOM_INVITE,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_TALK_CHAT_ROOM_TITLE_CHANGE,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_TALK_MSG_SEND,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_MSG_DELETE,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_TALK_LEAVE_ROOM,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_TALK_FAV_UESR_INSERT,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_TALK_CHAT_READ,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_USER_PROFILE_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener("ChangedNews", _mClassName);
      socket.removeLocalEventListener("SendNews", _mClassName);
      socket.removeLocalEventListener("SendNewsForInvited", _mClassName);

      socket.removeLocalEventListener(
        MsgIDList.EVENT_TALK_NEWS_SUBSCRIBE_CHANGED,
        _mClassName
      );
    };
  }, []);

  //loginInfo
  useEffect(() => {
    if (loginInfo && loginInfo.hasOwnProperty("user_id")) {
      requestFavUserList();
      requestChatNewsList((newsList) => {
        requestEntireChannelList((entireList) => {
          handleTalkNewsChannelList([
            ...newsList["returnData"],
            ...entireList["returnData"],
          ]);
        });
      });
      requestChatRoomList();
      requestAlertDisabledList();

      let enquiryManager = localStorage.getItem("enquiryManager");
      if (enquiryManager) {
        enquiryManager = enquiryManager.split("|");
        CREATE_ROOM(
          [{ cruUserSite: enquiryManager[0], cruUserID: enquiryManager[1] }],
          1
        );
        localStorage.removeItem("enquiryManager");
      }

      if (param.bizNum) {
        socket.fireLocalEventListener(
          MsgIDList.EVENT_LOGITALK_SEARCH_EXPORT_COMP_SELECT,
          _mClassName,
          param.bizNum,
          () => {}
        );
        changedActiveMainMenu("CorpInfoReference");
        window.history.replaceState(
          "",
          "",
          window.location.origin + "/LogiTalk"
        );
      }
    }
  }, [loginInfo]);

  //-----------------------------------------------------------------------------------------------------

  //로그인데이터 세팅
  const loginDataHandler = (value) => {
    if (typeof value !== "object") return;
    if (!value.hasOwnProperty("user_id")) return;
    let data = {};
    for (let key in value) {
      data[key] = value[key] ? value[key] : "";
    }
    data.USER_NICK = data.USER_NICK !== "" ? data.USER_NICK : data.user_nm;
    data.USER_PIC = data.USER_PIC !== "" ? data.USER_PIC : user_default_image;
    data.selfChatRoomKey = `${data.comp_cd}_${data.user_id}|${data.comp_cd}_${data.user_id}`;
    setLoginInfo(data);
  };
  // 전체메세지 채널 목록 불러오기
  const requestEntireChannelList = (callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_entire/JS_getEntireChannelListWithUser.php",
      msgID: msgID,
      data: {},
      cniUserSite: loginInfo.comp_cd,
      cniUser: loginInfo.user_id,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          for (let i = 0; i < newData["returnData"].length; i++) {
            if (newData["returnData"][i].cnmLastDelYN === "Y") {
              newData["returnData"][i].cnmLastMSG = "삭제된 메세지입니다.";
            }
            newData["returnData"][i].isEntire = true;
          }
          callback && callback(newData);
        }
      }
    });
  };

  const changedNewsHandler = (b, n) => {
    if (!n) return;

    requestChatNewsList((newsList) => {
      requestEntireChannelList((entireList) => {
        handleTalkNewsChannelList([
          ...newsList["returnData"],
          ...entireList["returnData"],
        ]);
      });
    });

    let activeList = [...activeChRoomList];
    const activeListIndex = activeList.findIndex(
      (item) =>
        item.hasOwnProperty("cnmMainKey") &&
        item.cnmMainKey === n.data.cnmMainKey
    );
    const isActiveNewsRoom =
      activeChatNewsRoom && activeChatNewsRoom.cnmMainKey === n.data.cnmMainKey;
    const isDelete =
      (n.data.hasOwnProperty("isSubscribe") && n.data.isSubscribe === "N") ||
      (n.data.hasOwnProperty("cniSites") &&
        typeof n.data.cniSites === "string" &&
        n.data.cniSites.indexOf(loginInfo.comp_cd) === -1);

    if (isDelete) {
      activeListIndex > -1 && activeList.splice(activeListIndex, 1);
      isActiveNewsRoom && setActiveChatNewsRoom(undefined);
      setActiveChRoomList(activeList);
    }
  };

  // 뉴스채널 목록 데이터 정리
  const handleTalkNewsChannelList = (cnmList) => {
    let dataList = [];
    subscribeList = {};
    for (let i = 0; i < cnmList.length; i++) {
      let data = cnmList[i];
      subscribeList[data.cnmMainKey] = "";
      if (!data.cnmPicMain) {
        data.cnmPicMain = data.COMP_LOGO;
      }
      dataList.push({
        ...data,
        LastTime: data.cnmLastTime,
      });
    }

    setNewsChatList(dataList);
  };

  // 구독 뉴스채널 데이터 요청
  const requestChatNewsList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_news/JS_getSubscribeChatNewsList.php",
      msgID: msgID,
      data: {},
      cnuUserSite: loginInfo.comp_cd,
      cnuUserID: loginInfo.user_id,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          for (let i = 0; i < newData["returnData"].length; i++) {
            if (newData["returnData"][i].cnmLastDelYN === "Y") {
              newData["returnData"][i].cnmLastMSG = "삭제된 메세지입니다.";
            }
            newData["returnData"][i].isEntire = false;
          }
          callback && callback(newData);
        }
      }
    });
  };

  //GBTS 사용자 정보 받아오기
  const requestGBTSUserList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_gbts_user_list.php",
      msgID: msgID,
      data: {},
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = {};
          for (let i = 0; i < newData["returnData"].length; i++) {
            let data = newData["returnData"][i];
            dataList[`${data.COMP_CD}_${data.USER_ID}`] = data;
          }

          user_list = { ...dataList };
        }
      }
    });
  };

  //알림 꺼둔 채팅방 리스트
  const requestAlertDisabledList = (comp_cd, val) => {
    if (!loginInfo.hasOwnProperty("comp_cd")) return;
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_talk_alert_disable_list.php",
      msgID: msgID,
      data: {},
      cradUserSite: loginInfo.comp_cd,
      cradUserID: loginInfo.user_id,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          const data = newData["returnData"];

          let alertDisabled = {};
          data.forEach((e) => {
            alertDisabled[e.cradMainKey] = "";
          });
          setAlertDisabledList(alertDisabled);
        }
      }
    });
  };

  //알림 끄기/켜기
  const updateAlertDisable = (type, chatRoomData) => {
    let msgID = util.makeUUIDv4();
    let data = {
      file: ALERT_DISABLE[type],
      msgID: msgID,
      data: {},
      cradMainKey: chatRoomData.crmMainKey,
      cradUserSite: loginInfo.comp_cd,
      cradUserID: loginInfo.user_id,
    };
    socket.sendMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          let dataList = { ...alertDisabledList };
          if (type === "INSERT") {
            dataList[chatRoomData.crmMainKey] = "";
          }
          if (type === "DELETE") {
            delete dataList[chatRoomData.crmMainKey];
          }
          setAlertDisabledList(dataList);
        }
      }
    });
  };

  //즐겨찾기된 유저리스트 세팅
  const requestFavUserList = () => {
    let msgID = util.makeUUIDv4();
    let data = {
      file: "/talk/JS_talk_fav_user_select_2.php",
      msgID: msgID,
      data: {},
      cfuUserSite: loginInfo.comp_cd,
      cfuUserID: loginInfo.user_id,
    };

    socket.sendMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length) {
          let userList = {};
          newData["returnData"].forEach((user) => {
            for (let key in user) {
              if (user[key] === null) {
                user[key] = "";
              }
            }
            user.USER_NICK =
              user.USER_NICK !== "" ? user.USER_NICK : user.USER_NM;
            user.USER_PIC = util.changeURIForThumbnail(user.USER_PIC);

            if (userList.hasOwnProperty(user.COMP_CD)) {
              userList[user.COMP_CD] = {
                ...userList[user.COMP_CD],
                userList: {
                  ...userList[user.COMP_CD].userList,
                  [user.USER_ID]: { ...user },
                },
              };
            } else {
              userList[user.COMP_CD] = {
                COMP_CD: user.COMP_CD,
                COMP_NM: user.COMP_NM,
                COMP_GB: user.COMP_GB,
                userList: { [user.USER_ID]: { ...user } },
              };
            }
          });
          return requestFavUserLoginState(userList);
        }
      }
    });
  };

  //유저 접속 여부
  const requestFavUserLoginState = (users) => {
    let msgID = util.makeUUIDv4();
    let dataList = { ...users };
    let userList = [];
    for (let key in dataList) {
      for (let id in dataList[key].userList) {
        userList.push({
          COMP_CD: dataList[key].userList[id].COMP_CD,
          USER_ID: dataList[key].userList[id].USER_ID,
        });
      }
    }
    let data = {
      method: MsgIDList.EVENT_TALK_LOGIN_STATE_GET,
      msgID: msgID,
      data: {
        users: [...userList],
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length) {
          newData["returnData"].forEach((loginState) => {
            dataList[loginState.COMP_CD].activeState = true;
            dataList[loginState.COMP_CD].userList[
              loginState.USER_ID
            ].login_state = loginState.STATE;
          });
          setFavUserList(dataList);
        }
      }
    });
  };

  //즐겨찾기 유저 삭제
  const deleteFavUser = (user) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_talk_fav_user_delete.php",
      msgID: msgID,
      data: {},
      cfuUserSite: loginInfo.comp_cd, //내 comp_cd
      cfuUserID: loginInfo.user_id, //내 user_id
      cfuFavUserSite: user.COMP_CD, //대상 comp_cd
      cfuFavUserID: user.USER_ID, //대상 user id
    };
    codeList.Modal.current.confirm(
      `[${user.COMP_NM}] ${
        user.USER_NICK ? user.USER_NICK : user.USER_NM
      } 님을 대화상대에서 삭제하시겠습니까?`,
      (ret) => {
        if (ret) {
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData.ret) {
                codeList.Modal.current.alert(
                  "정상적으로 삭제되었습니다.",
                  () => {
                    requestFavUserList();
                  }
                );
              } else {
                codeList.Modal.current.alert(
                  "삭제중 오류가 발생하였습니다. 관리자에게 문의바랍니다."
                );
              }
            }
          });
        } else {
          codeList.Modal.current.alert("취소하였습니다.");
        }
      }
    );
  };

  //채팅 유저 선택 및 채팅방 유무 확인
  const checkSelectedUserChat = (chComp, chatUserData) => {
    try {
      let findChatRoom = undefined;

      for (let i = 0; i < chatRoomList.length; i++) {
        const item = chatRoomList[i];
        if (chatRoomList[i].crmType !== "1") continue;
        if (
          item.crmMainKey ===
            `${loginInfo.comp_cd}_${loginInfo.user_id}|${chatUserData.COMP_CD}_${chatUserData.USER_ID}` ||
          item.crmMainKey ===
            `${chatUserData.COMP_CD}_${chatUserData.USER_ID}|${loginInfo.comp_cd}_${loginInfo.user_id}`
        ) {
          findChatRoom = item;
          break;
        }
      }

      if (findChatRoom !== undefined) {
        addActiveChRoom(findChatRoom);
      } else {
        requestChatting(chComp, chatUserData, requestChatRoomList);
      }
    } catch (e) {
      codeList.Modal.current.alert(
        "오류가 발생했습니다. 새로고침 후 다시 시도해주세요"
      );
    }
  };

  //채팅방 생성여부 확인
  const requestChatting = (chComp, chatUserList, callback) => {
    codeList.Modal.current.confirm(
      `[${chComp.COMP_NM}] ${
        chatUserList.USER_NICK || chatUserList.USER_NM
      }님에게 대화를 요청하시겠습니까?`,

      (ret) => {
        if (ret) {
          let selectUserList = [
            {
              cruUserSite: chComp.COMP_CD,
              cruUserID: chatUserList.USER_ID,
            },
          ];
          CREATE_ROOM(selectUserList, 1, callback);
        } else {
          codeList.Modal.current.alert("대화신청을 취소하였습니다.");
        }
      }
    );
  };

  //채팅방  생성
  const CREATE_ROOM = (selectUserList, crmType, callback) => {
    let msgID = util.makeUUIDv4();

    let data = {
      method: MsgIDList.EVENT_SOCKET_ROOM_CREATE,
      msgID: msgID,
      data: {
        crmMainKey: "",
        crmType: crmType, //1:PtoP 2:Private 3:Public 오픈채팅 4:PublicSecret 비공개
        crmAllow: "N",
        crmName: "Private Chat",
        crmPWD: "",
        crmLastMSG: `${
          loginInfo.USER_NICK ? loginInfo.USER_NICK : loginInfo.user_nm
        }님이 대화를 요청하였습니다.`,
        crmCreator: loginInfo.user_id,
        crmCreatorSite: loginInfo.comp_cd,
        chatUserList: [...selectUserList],
        senderCompCD: loginInfo.comp_cd,
        senderUserID: loginInfo.user_id,
        senderUserName: loginInfo.user_nm,
        senderUserNICK: loginInfo.USER_NICK,
        senderUserPART: loginInfo.USER_PART_NM,
        senderUserTEAM: loginInfo.USER_TEAM_NM,
        senderUserPIC: loginInfo.USER_PIC,
      },
    };

    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {
      if (callback) {
        callback();
      }
    });
  };

  //단체방 사용자 추가
  const EVENT_SOCKET_ROOM_INVITE = (selectUserList, crmMainKey, callback) => {
    let msgID = util.makeUUIDv4();

    let data = {
      method: MsgIDList.EVENT_SOCKET_ROOM_INVITE,
      msgID: msgID,
      data: {
        crmMainKey: crmMainKey,
        crtMSG: `${
          loginInfo.USER_NICK ? loginInfo.USER_NICK : loginInfo.user_nm
        }님이 대화방에 초대하였습니다.`,
        chatUserList: [...selectUserList],
        senderCompCD: loginInfo.comp_cd,
        senderUserID: loginInfo.user_id,
        senderUserName: loginInfo.user_nm,
        senderUserNICK: loginInfo.USER_NICK,
        senderUserPART: loginInfo.USER_PART_NM,
        senderUserTEAM: loginInfo.USER_TEAM_NM,
        senderUserPIC: loginInfo.USER_PIC,
        senderUserCOMPNM: loginInfo.comp_nm,
      },
    };

    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {
      if (callback) {
        callback();
      }
    });
  };

  //메세지 받기
  const messageGetHandler = (value, MsgKey) => {
    if (typeof value["returnData"][0] !== "object") return;

    let msg = { ...value["returnData"][0] };
    let chatList = [...chatRoomList];
    let activeList = [...activeChRoomList];

    let userIndex = msg.chatUserList.findIndex(
      (user) =>
        user.cruUserID === loginInfo.user_id &&
        user.cruUserSite === loginInfo.comp_cd
    );
    let chatIndex = chatList.findIndex(
      (chat) => chat.crmMainKey === msg.crmMainKey
    );
    let activeIndex = activeList.findIndex(
      (chat) => chat.crmMainKey === msg.crmMainKey
    );

    chatList.chatUserList = msg.chatUserList;
    // activeList.chatUserList = msg.chatUserList;

    if (chatIndex === -1 && isWindowActive) {
      chatList = [msg, ...chatList];
      chatIndex = 0;
      userIndex = msg.chatUserList.findIndex(
        (val) =>
          val.cruUserID === loginInfo.user_id &&
          val.cruUserSite === loginInfo.comp_cd
      );
      chatList[chatIndex].chatUserList[userIndex].unreadCount = 0;
    }

    if (userIndex > -1 && MsgKey !== MsgIDList.EVENT_SOCKET_TALK_CHAT_READ) {
      msg.chatUserList[userIndex].unreadCount =
        Number(chatList[chatIndex].chatUserList[userIndex].unreadCount) + 1;
    }

    if (activeChRoom.hasOwnProperty("crmMainKey") && isWindowActive) {
      if (activeChRoom.crmMainKey === msg.crmMainKey) {
        msg.chatUserList[userIndex].unreadCount = 0;
        setActiveChRoom(msg);
      }
    }

    if (activeIndex > -1) {
      activeList[activeIndex].chatUserList = [...msg.chatUserList];
    }

    msg = {
      ...chatList[chatIndex],
      chatUserList: [...msg.chatUserList],
      crmLastMSG: msg.crmLastMSG,
      crmLastTime: msg.crmLastTime,
      crmLastUser: msg.crmLastUser,
      crmLastUserSite: msg.crmLastUserSite,
      LastTime: new Date(),
    };

    // chatList.splice(chatIndex, 1);
    // chatList.unshift(msg);

    chatList[chatIndex] = msg;

    chatList = chatRoomDateSort(chatList);
    chatList = chatRoomDataHandler(chatList);
    activeList = chatRoomDataHandler(activeList);

    setActiveChRoomList(activeList);
    chatDataList = [...chatList];
    setChatRoomList(chatList);

    if (
      value.method === "EVENT_SOCKET_MSG_DELETE" ||
      value.method === "EVENT_SOCKET_TALK_CHAT_READ"
    ) {
      return;
    }

    handleNotifyData(value.data, value.msgID);
  };

  const newsChatGetHandler = (n) => {
    if (!n) return;
    if (n.data.cntDelYN === "Y") return;
    if (!subscribeList.hasOwnProperty(n.data.cnmMainKey)) return; //미구독 채널 알림톡 제외
    let dataList = [...newsChatList];
    let i = dataList.findIndex((data) => data.cnmMainKey === n.data.cnmMainKey);

    if (i < 0) return;

    dataList[i] = {
      ...dataList[i],
      ...n.data,
      cnmLastMSG: n.data.cntMSG,
      cnmLastTime: new Date(),
      LastTime: new Date(),
      cnmUnreadCnt: Number(dataList[i].cnmUnreadCnt) + 1,
    };

    let activeList = [...activeChRoomList];
    let activeIndex = activeList.findIndex(
      (item) => item.cnmMainKey === n.data.cnmMainKey
    );
    if (activeIndex > -1) {
      activeList[activeIndex].cnmUnreadCnt =
        Number(dataList[i].cnmUnreadCnt) + 1;
    }

    setActiveChRoomList(activeList);
    setNewsChatList(dataList);
    handleNotifyData(n.data, n.data.cntMSGKey);
  };

  //채팅방 날짜 내림차순 정렬
  const chatRoomDateSort = (dataList) => {
    dataList.sort(function (a, b) {
      a = new Date(a.crmLastTime);
      b = new Date(b.crmLastTime);
      return a > b ? -1 : a < b ? 1 : 0;
    });

    return dataList;
  };

  // 채팅 리스트 받아오기
  const requestChatRoomList = () => {
    if (!loginInfo.hasOwnProperty("user_id")) return;
    const msgID = util.makeUUIDv4();
    const data = {
      method: MsgIDList.EVENT_SOCKET_ROOM_LIST_GET,
      msgID: msgID,
      data: {
        senderCompCD: loginInfo.comp_cd,
        senderUserID: loginInfo.user_id,
      },
    };

    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length > 0) {
          let dataList = chatRoomDateSort([...newData["returnData"]]);
          chatRoomListHandler(dataList);
        }
      }
    });
  };

  //채팅방 정보 및 채팅 내  유저 데이터 세팅
  const chatRoomListHandler = (data) => {
    let dataList = chatRoomDataHandler(data);
    chatDataList = [...dataList];
    setChatRoomList(dataList);
    if (eventFlow.hasOwnProperty(MsgIDList.EVENT_SOCKET_ROOM_LIST_GET)) {
      if (eventFlow[MsgIDList.EVENT_SOCKET_ROOM_LIST_GET].length > 0) {
        for (
          let i = eventFlow[MsgIDList.EVENT_SOCKET_ROOM_LIST_GET].length - 1;
          i > -1;
          i--
        ) {
          eventFlow[MsgIDList.EVENT_SOCKET_ROOM_LIST_GET][i]();
          eventFlow[MsgIDList.EVENT_SOCKET_ROOM_LIST_GET].splice(i, 1);
        }
      }
    }
  };

  //채팅리스트 데이터 정리
  const chatRoomDataHandler = (data) => {
    let dataList = [...data];
    let dataListSet = [];
    for (let i = 0; i < dataList.length; i++) {
      if (!dataList[i].hasOwnProperty("crmMainKey")) {
        dataListSet.push(dataList[i]);
        continue;
      }

      let userNames = [];
      let cruRoomName = "";
      let users = [...dataList[i].chatUserList];
      let isMe = "(알수없음)";
      if (users.length > 0) {
        users.forEach((user, index) => {
          let isGuest = user.cruUserSite.indexOf("-") > -1;

          if (user.UserPIC) {
            user.UserPIC = util.changeURIForThumbnail(user.UserPIC);
          }
          if (
            user.cruUserID !== loginInfo.user_id ||
            user.cruUserSite !== loginInfo.comp_cd
          ) {
            users[index].UserNICK = user.UserNICK || user.UserName;
            users[index].UserPIC = user.UserPIC || user_default_image;
            userNames.push(
              `[${isGuest ? "GUEST" : user.UserCOMPNM}] ${
                user.UserNICK || user.UserName
              }`
            );
          } else {
            if (user.cruRoomName) {
              cruRoomName = user.cruRoomName;
            }
            isMe = `[${isGuest ? "GUEST" : user.UserCOMPNM}] ${
              user.UserNICK || user.UserName
            }`;
          }
        });

        let chatRoomTitleSet = userNames.join(", ").slice(0, 40);

        dataList[i].LastTime = dataList[i].crmLastTime;
        if (cruRoomName !== "") {
          dataList[i].chatRoomTitle = cruRoomName;
        } else {
          if (userNames.length > 0) {
            dataList[i].chatRoomTitle = chatRoomTitleSet;
          } else {
            dataList[i].chatRoomTitle = isMe;
          }
        }
        dataListSet.push(dataList[i]);
      }
    }

    return dataListSet;
  };

  //대화상대 리스트 프로필 핸들러
  const favUserListProfileHandler = (value) => {
    if (typeof value !== "object") return;
    if (!value.hasOwnProperty("USER_ID")) return;
    let data = { ...value };
    let favUsers = { ...favUserList };
    let changeUser = favUsers[data.COMP_CD].userList[data.USER_ID];
    favUsers[data.COMP_CD].userList[data.USER_ID] = {
      ...changeUser,
      ...data,
      USER_NICK: changeUser.USER_NICK
        ? changeUser.USER_NICK
        : changeUser.USER_NM,
    };

    setFavUserList(favUsers);
  };

  //채팅방내 사용자 프로필 핸들러
  const chatRoomUserProfileHandler = (value) => {
    if (typeof value !== "object") return;
    if (!value.hasOwnProperty("USER_ID")) return;
    let data = { ...value };
    let chatList = [...chatRoomList];
    for (let i = 0; i < chatList.length; i++) {
      let chatRoomData = chatList[i];
      chatRoomData.chatUserList.forEach((chatRoomUser, index) => {
        if (chatRoomUser.cruUserID === data.USER_ID) {
          chatList[i].chatUserList[index] = {
            ...chatList[i].chatUserList[index],
            UserNICK: data.USER_NICK,
            UserPART: data.USER_PART_NM,
            UserTEAM: data.USER_TEAM_NM,
            UserPIC: data.USER_PIC,
          };
        }
      });
    }
    chatDataList = [...chatList];
    setChatRoomList(chatList);
  };

  //-----UI Control------------------------------------------------------------------------------------------------

  //모바일 여부 확인
  const isMobileHandler = () => {
    let inWidth = window.innerWidth;
    if (inWidth >= 960) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  //메뉴이동
  const changedActiveMainMenu = (name) => {
    let pageList = { ...pagesOpen };
    for (let key in pageList) {
      pageList[key] = key === name;
    }
    setPagesOpen(pageList);
  };

  //유저리스트 열기 닫기
  const favUserListDropDown = (comp_cd, val) => {
    let dataList = { ...favUserList };
    dataList = {
      ...dataList,
      [comp_cd]: {
        ...dataList[comp_cd],
        activeState: val,
      },
    };
    setFavUserList(dataList);
  };

  //채팅방 나가기
  const deleteChatRoom = (chRoom) => {
    if (!chRoom.hasOwnProperty("crmMainKey")) {
      return;
    }
    let dataList = [...chatRoomList];
    let delInd = dataList.findIndex(
      (data) => data.crmMainKey === chRoom.crmMainKey
    );
    if (delInd > -1) {
      dataList.splice(delInd, 1);
    }
    chatDataList = [...dataList];
    setChatRoomList(dataList);
    delActiveChRoom(chRoom);
  };

  //활성화 채팅 추가
  const addActiveChRoom = (chRoom) => {
    let activeList = [...activeChRoomList];
    let chRoomIndex = activeList.findIndex(
      (ch) => chRoom.crmMainKey === ch.crmMainKey
    );
    if (chRoomIndex === -1) {
      activeList = [...activeList, chRoom];
      setActiveChRoomList(activeList);
    }
    changeActiveChatRoom(chRoom);
  };

  //활성 채팅방 변경
  const changeActiveChatRoom = (chRoom) => {
    setActiveChRoom({});
    activeChRoomChange(chRoom);
  };

  // 활성화 채팅 변경
  const activeChRoomChange = (chRoom) => {
    let chatList = [...chatRoomList];
    if (chRoom.hasOwnProperty("crmMainKey")) {
      let chatIndex = chatList.findIndex(
        (value) => value.crmMainKey === chRoom.crmMainKey
      );

      if (chatIndex > -1) {
        let userIndex = chatList[chatIndex].chatUserList.findIndex(
          (value) =>
            value.cruUserID === loginInfo.user_id &&
            value.cruUserSite === loginInfo.comp_cd
        );
        let user = chatList[chatIndex].chatUserList[userIndex];
        if (user && user.cruAgreeYN === "Y") {
          user.unreadCount = 0;
        }
      } else {
        chatList.unshift(chRoom);
      }

      let activeList = [...activeChRoomList];
      let activeIndex = activeList.findIndex(
        (value) => value.crmMainKey === chRoom.crmMainKey
      );
      if (activeIndex > -1) {
        let userIndex = activeList[activeIndex].chatUserList.findIndex(
          (value) =>
            value.cruUserID === loginInfo.user_id &&
            value.cruUserSite === loginInfo.comp_cd
        );
        if (
          activeList[activeIndex].chatUserList[userIndex].cruAgreeYN === "Y"
        ) {
          activeList[activeIndex].chatUserList[userIndex].unreadCount = 0;
        }
      }
    }

    setTimeout(() => {
      chatDataList = [...chatList];
      setChatRoomList([...chatList]);
      setActiveChatNewsRoom(undefined);
      setActiveChRoom({ ...chRoom });
    }, 10);
  };

  //활성화 채팅 닫기
  const delActiveChRoom = (chRoom) => {
    let activeList = [...activeChRoomList];
    let activeIndex = activeList.findIndex(
      (ch) => chRoom.crmMainKey === ch.crmMainKey
    );

    if (activeIndex > -1) {
      activeList.splice(activeIndex, 1);

      if (chRoom.crmMainKey === activeChRoom.crmMainKey) {
        setActiveChRoom({});
      }
      setActiveChRoomList(activeList);
    }
  };

  const openNewsChatRoom = (data) => {
    setActiveChRoomList((prevState) => {
      if (
        prevState.find((i) => i.cnmMainKey === data.cnmMainKey) === undefined
      ) {
        return [...prevState, data];
      }
      return [...prevState];
    });
    setActiveChRoom({});
    setActiveChatNewsRoom(data);
  };

  const closeNewsChatRoom = (data) => {
    let dataList = [...activeChRoomList];
    let deleteIndex = dataList.findIndex(
      (item) => item.cnmMainKey === data.cnmMainKey
    );
    dataList.splice(deleteIndex, 1);
    setActiveChRoomList(dataList);
    if (
      activeChatNewsRoom &&
      data.cnmMainKey === activeChatNewsRoom.cnmMainKey
    ) {
      setActiveChatNewsRoom(undefined);
    }
  };

  // 알림톡 미확인 메세지 카운터 초기화
  const clearNewsUnreadCnt = (mainKey) => {
    if (!mainKey) return;
    const i = newsChatList.findIndex((item) => item.cnmMainKey === mainKey);
    if (i < 0) return;
    let dataList = [...newsChatList];
    dataList[i] = {
      ...dataList[i],
      cnmUnreadCnt: 0,
    };

    let activeList = [...activeChRoomList];
    const activeIndex = activeList.findIndex(
      (item) => item.cnmMainKey === mainKey
    );
    if (activeIndex > -1) {
      activeList[activeIndex].cnmUnreadCnt = 0;
    }

    setActiveChRoomList(activeList);
    setNewsChatList(dataList);
    const ChangedNews = (news) => {
      const msgID = util.makeUUIDv4();
      const data = {
        method: "ChangedNews",
        msgID: msgID,
        data: {
          ...news,
        },
      };
      socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
    };
  };

  const ChangedNews = (news) => {
    const msgID = util.makeUUIDv4();
    const data = {
      method: "ChangedNews",
      msgID: msgID,
      data: {
        ...news,
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
  };
  // ws 구독 데이터 전송
  // 구독
  const actionSubscribe = (news) => {
    const isSubscribe = subscribeList.hasOwnProperty(news.cnmMainKey);
    const confirmMSG = isSubscribe
      ? "채널을 구독취소하시겠습니까?"
      : "채널을 구독하시겠습니까?";

    codeList.Modal.current.confirm(
      `${news.cnmCompNM}의 [${news.cnmName}] ${confirmMSG}`,
      (ret) => {
        if (ret) {
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/talk_news/JS_chatNewsChannelSubscribe.php",
            msgID: msgID,
            data: {},
            cnuMainKey: news.cnmMainKey,
            cnuUserSite: loginInfo.comp_cd,
            cnuUserID: loginInfo.user_id,
            cnuRoomAuth: "",
            cnuRoomName: news.cnmName,
            cnuAgreeYN: isSubscribe ? "N" : "Y",
            cnuJoinDate: new Date(),
          };
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                codeList.Modal.current.alert(
                  socketMsg.cnuAgreeYN === "Y"
                    ? `[${news.cnmName}]이 구독되었습니다.`
                    : `[${news.cnmName}]이 구독 취소되었습니다.`,
                  () => {
                    requestChatNewsList((newsList) => {
                      requestEntireChannelList((entireList) => {
                        handleTalkNewsChannelList([
                          ...newsList["returnData"],
                          ...entireList["returnData"],
                        ]);
                      });
                    });
                    ChangedNews({
                      ...news,
                      cnuAgreeYN: socketMsg.cnuAgreeYN,
                      isSubscribe: isSubscribe ? "N" : "Y",
                    });
                  }
                );
              } else {
                codeList.Modal.current.alert(
                  "오류가 발생했습니다. 다시 시도해주세요"
                );
              }
            }
          });
        }
      }
    );
  };

  //프로필수정
  const changeProfile = (val) => {
    let user = { ...loginInfo };
    for (let key in val) {
      if (user.hasOwnProperty(key)) {
        user[key] = val[key];
      }
    }
    setLoginInfo(user);
  };

  // -----notify custom------

  const handleNotifyData = (data, msgID) => {
    try {
      const isNews = data.hasOwnProperty("cntMainKey");
      let notifyData = {
        ...data,
        isNews: isNews,
      };

      if (isNews) {
        notifyData = {
          ...notifyData,
          name: data.cnmName,
          nick: data.cnmName,
          pic: data.COMP_LOGO,
          msg: data.cntTitle,
          msgID: msgID,
          senderCompCD: data.cntCreatorSite,
          senderUserID: data.cntCreator,
        };
      } else {
        notifyData = {
          ...notifyData,
          name: data.senderUserName,
          nick: data.senderUserNICK,
          pic: data.senderUserPIC,
          msg: data.hasOwnProperty("crmLastMSG")
            ? data.crmLastMSG
            : data.crtMSG,
          msgID: msgID,
          senderCompCD: data.senderCompCD,
          senderUserID: data.senderUserID,
        };
        if (notifyData.msg.indexOf("|¿|") > -1) {
          let msgSplit = notifyData.msg.split("|¿|");
          msgSplit.splice(0, 3);
          notifyData.msg = msgSplit.join("");
        }
      }

      notify(notifyData);
    } catch (e) {
      console.log(e);
    }
  };

  //------notify------
  const notifyHandler = (value, msgID) => {
    let notifyData = {
      ...value,
      crtMainKey: value.hasOwnProperty("crtMainKey")
        ? value.crtMainKey
        : value.crmMainKey,
      name: value.senderUserName,
      nick: value.senderUserNICK,
      pic: value.senderUserPIC,
      msg: value.hasOwnProperty("crmLastMSG") ? value.crmLastMSG : value.crtMSG,
      msgID: msgID,
      senderCompCD: value.senderCompCD,
      senderUserID: value.senderUserID,
    };

    if (notifyData.msg.indexOf("|¿|") > -1) {
      let msgSplit = notifyData.msg.split("|¿|");
      msgSplit.splice(0, 3);
      notifyData.msg = msgSplit.join("");
    }
    notify(notifyData);
  };

  const notify = (props) => {
    if (lastMsg === props.msgID) return;
    if (!props.isNews) {
      if (alertDisabledList.hasOwnProperty(props.crtMainKey)) return;
      if (
        props.senderUserID === loginInfo.user_id &&
        props.senderCompCD === loginInfo.comp_cd
      )
        return;
    }

    lastMsg = props.msgID;

    const options = {
      onOpen: (props) => {
        //console.log(props);
      },
      onClose: (props) => {
        //console.log(props);
      },
      onClick: (props) => {
        //console.log(e, v, props);
      },
      autoClose: 3000,
      closeOnClick: true,
    };
    if (isMobile) {
      options.position = toast.POSITION.TOP_CENTER;
    }

    if (!isMobile) {
      options.position = toast.POSITION.BOTTOM_LEFT;
      options.className = !isMobile ? "custom_notify_position" : "";
    }

    toast(<ToastLayout {...props} />, options);
    notifyWindow({ ...props });
  };

  const ToastLayout = (props) => {
    const handleClick = () => {
      if (props.isNews) {
        openNewsChatRoom(props);
        return;
      }

      notifyByActiveChatRoom(props.crtMainKey);
    };
    return (
      <div
        onClick={handleClick}
        className="customized_notify alert alert-info"
        style={{
          display: "inline-block",
          margin: "0px auto",
          height: "50px",
        }}
      >
        <span data-notify="title">
          <a>
            <h4>{props.name}</h4>
          </a>
        </span>
        <a>
          <span data-notify="message">
            <figure
              style={{
                background: `url(${
                  props.pic === null
                    ? "https://www.gbts.co.kr/images/user_pic-50x50.png"
                    : props.pic
                }) 50% 50% / cover no-repeat`,
              }}
            />
          </span>
        </a>
        <p>
          <a>{props.msg}</a>
        </p>
      </div>
    );
  };

  const notifyWindow = (props) => {
    let img =
      props.pic === null
        ? "https://www.gbts.co.kr/images/user_pic-50x50.png"
        : props.pic;
    let text = props.msg;
    let notification = new Notification(props.name, {
      body: text,
      icon: img,
    });

    setTimeout(notification.close.bind(notification), 6000);
    notification.onclick = function (event) {
      event.preventDefault(); // prevent the browser from focusing the Notification's tab
      if (props.isNews) {
        openNewsChatRoom(props);
        return;
      }
      notifyByActiveChatRoom(props.crtMainKey);
      // console.log(props.crtMainKey);
      // console.log(event);
    };
    if (!isWindowActive) {
      navigator.setAppBadge(badgeCont + 1).catch((error) => {
        //Do something with the error.
      });

      setBadgeCont(badgeCont + 1);
    }

    //navigator.clearAppBadge()
  };

  const notifyByActiveChatRoom = (key) => {
    let data = chatDataList.find((chatRoom) => chatRoom.crmMainKey === key);
    if (!data) return;
    if (!data.hasOwnProperty("crmMainKey")) return;
    addActiveChRoom(data);
  };

  const askNotificationPermission = () => {
    // 권한을 실제로 요구하는 함수
    function handlePermission(permission) {
      // 사용자의 응답에 관계 없이 크롬이 정보를 저장할 수 있도록 함
      if (!("permission" in Notification)) {
        Notification.permission = permission;
      }
    }

    // 브라우저가 알림을 지원하는지 확인
    if (!("Notification" in window)) {
      console.log("이 브라우저는 알림을 지원하지 않습니다.");
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
          handlePermission(permission);
        });
      } else {
        Notification.requestPermission(function (permission) {
          handlePermission(permission);
        });
      }
    }
  };

  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  };

  const ChatNewsTabRender = useCallback((props) => {
    return <ChatNewsTab {...props} />;
  }, []);

  const ChatRoomTabRender = useCallback((props) => {
    return <ChatRoomTab {...props} />;
  }, []);

  const pageProps = {
    loginInfo,
    isMobile,
    favUserList,
    newsChatList,
    subscribeList,
    chatRoomList,
    alertDisabledList,
    activeChRoom,
    chatDataList,
    isWindowActive,
    actionSubscribe,
    openNewsChatRoom,
    closeNewsChatRoom,
    changedActiveMainMenu,
    addActiveChRoom,
    favUserListDropDown,
    deleteFavUser,
    checkSelectedUserChat,
    CREATE_ROOM,
    EVENT_SOCKET_ROOM_INVITE,
    changeProfile,
  };
  const chProps = {
    loginInfo,
    activeChRoom,
    isMobile,
    alertDisabledList,
    delActiveChRoom,
    changedActiveMainMenu,
    updateAlertDisable,
    deleteChatRoom,
    isWindowActive,
    user_list,
  };

  return (
    <>
      <AutoLogin />

      <ChatNewsCompModal loginInfo={loginInfo} newsChatList={newsChatList} />

      <div
        className={`d-flex ch_height ${isMobile ? "flex-column" : "flex-row"}`}
      >
        <LogiTalkNav
          isMobile={isMobile}
          changedActiveMainMenu={changedActiveMainMenu}
          pagesOpen={pagesOpen}
          pages={pages}
        />

        {/*실행 컴포넌트 랜더링*/}
        {loginInfo &&
          pages.map((p) => {
            return (
              <div
                key={p.id}
                style={{
                  display: pagesOpen[p.id] ? "block" : "none",
                  minWidth: 400,
                  paddingBottom: isMobile ? 78 : "",
                }}
                className={`${
                  p.id.indexOf("List") > -1
                    ? isMobile
                      ? "w-100 bg-white h-100"
                      : ""
                    : "w-100  h-100"
                }`}
              >
                {p.component(pageProps)}
              </div>
            );
          })}
        {/*채팅방 탭메뉴 구현*/}
        {!isMobile &&
          (pagesOpen.LogiTalkUserList || pagesOpen.LogiTalkChatList) && (
            <div
              className="flex-fill h-100 d-flex flex-column justify-content-start"
              style={{ maxHeight: "100vh" }}
            >
              <ul
                id="chat-tab"
                className="nav nav-tabs w-100 p-0 m-0 flex-wrap-reverse"
              >
                {activeChRoomList.length > 0 &&
                  activeChRoomList.map((chRoom) => {
                    if (chRoom.hasOwnProperty("cnmMainKey")) {
                      return (
                        <ChatNewsTabRender
                          key={chRoom.cnmMainKey}
                          chRoom={chRoom}
                          activeChatNewsRoom={activeChatNewsRoom}
                          closeNewsChatRoom={closeNewsChatRoom}
                          openNewsChatRoom={openNewsChatRoom}
                        />
                      );
                    }

                    return (
                      <ChatRoomTabRender
                        key={chRoom.crmMainKey}
                        chRoom={chRoom}
                        loginInfo={loginInfo}
                        activeChRoom={activeChRoom}
                        changeActiveChatRoom={changeActiveChatRoom}
                        delActiveChRoom={delActiveChRoom}
                      />
                    );
                  })}
              </ul>

              <div className="flex-fill">
                {activeChatNewsRoom !== undefined && (
                  <ChatNewsRoom
                    loginInfo={loginInfo}
                    isMobile={isMobile}
                    isWindowActive={isWindowActive}
                    activeChatNewsRoom={activeChatNewsRoom}
                    alertDisabledList={alertDisabledList}
                    closeNewsChatRoom={closeNewsChatRoom}
                    actionSubscribe={actionSubscribe}
                    clearNewsUnreadCnt={clearNewsUnreadCnt}
                    updateAlertDisable={updateAlertDisable}
                  />
                )}
                {activeChRoom.hasOwnProperty("crmMainKey") && (
                  <LogiTalkChatRoom {...chProps} />
                )}
                {activeChatNewsRoom === undefined &&
                  !activeChRoom.hasOwnProperty("crmMainKey") && (
                    <div className="w-100 h-100 d-flex flex-row justify-content-center align-items-center bg-light">
                      <h5 className="font-weight-bold text-secondary">
                        활성화된 채팅방이 없습니다.
                      </h5>
                    </div>
                  )}
              </div>
            </div>
          )}

        {isMobile && activeChRoom.hasOwnProperty("crmMainKey") && (
          <div
            className="fixed-top"
            style={{
              zIndex: 1030,
              top: 0,
              left: 0,
              height: "100vh",
            }}
          >
            <LogiTalkChatRoom {...chProps} />
          </div>
        )}
        {isMobile && activeChatNewsRoom !== undefined && (
          <div
            className="fixed-top"
            style={{
              zIndex: 1030,
              top: 0,
              left: 0,
              height: "100vh",
            }}
          >
            <ChatNewsRoom
              loginInfo={loginInfo}
              isMobile={isMobile}
              isWindowActive={isWindowActive}
              activeChatNewsRoom={activeChatNewsRoom}
              alertDisabledList={alertDisabledList}
              closeNewsChatRoom={closeNewsChatRoom}
              actionSubscribe={actionSubscribe}
              clearNewsUnreadCnt={clearNewsUnreadCnt}
              updateAlertDisable={updateAlertDisable}
            />
          </div>
        )}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </>
  );
}
const ChatNewsTab = React.memo((props) => {
  const { chRoom, activeChatNewsRoom, openNewsChatRoom, closeNewsChatRoom } =
    props;

  return (
    <li className="nav-item m-0 p-0" style={{ width: "33.3333%" }}>
      <div
        className={`nav-link h-100 w-100 d-flex flex-row align-items-center justify-content-start font-weight-bold m-0 p-0 ${
          chRoom.cnmMainKey === activeChatNewsRoom?.cnmMainKey ? "active" : ""
        }`}
      >
        <div
          className="text-dark flex-fill overflow-hidden p-2 px-3 cursor_pointer"
          onClick={() => {
            openNewsChatRoom(chRoom);
          }}
        >
          <div className="font-weight-bold d-flex flex-row">
            <div
              className="d-inline-block overflow-hidden text-truncate"
              style={{ maxWidth: 400 }}
            >
              {chRoom.cnmName}
            </div>
            <div className="position-relative d-inline-block">
              {Number(chRoom.cnmUnreadCnt) > 0 && (
                <div
                  className="d-inline-block rounded-circle position-absolute d-flex flex-row justify-content-center align-items-center text-white font-weight-bold"
                  style={{
                    width: 10,
                    height: 10,
                    left: "calc(100% + 5px)",
                    top: "50%",
                    marginTop: -5,
                    background: "rgb(59, 68, 172)",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className="cursor_pointer p-2 px-3"
          onClick={() => {
            closeNewsChatRoom(chRoom);
          }}
        >
          &times;
        </div>
      </div>
    </li>
  );
});

const ChatRoomTab = React.memo((props) => {
  const {
    chRoom,
    loginInfo,
    activeChRoom,
    changeActiveChatRoom,
    delActiveChRoom,
  } = props;

  let chatUser = {};

  chRoom.chatUserList.forEach((user) => {
    if (
      loginInfo.user_id === user.cruUserID &&
      loginInfo.comp_cd === user.cruUserSite
    ) {
      chatUser = { ...user };
    }
  });
  return (
    <li
      key={chRoom.crmMainKey}
      className="nav-item m-0 p-0"
      style={{ width: "33.3333%" }}
    >
      <div
        className={`nav-link h-100 w-100 d-flex flex-row align-items-center justify-content-start font-weight-bold m-0 p-0 ${
          chRoom.crmMainKey === activeChRoom.crmMainKey ? "active" : ""
        }`}
      >
        <div
          className="text-dark flex-fill overflow-hidden p-2 px-3 cursor_pointer"
          onClick={() => {
            changeActiveChatRoom(chRoom);
          }}
        >
          <div className="font-weight-bold d-flex flex-row">
            <div
              className="d-inline-block overflow-hidden text-truncate"
              style={{ maxWidth: 400 }}
            >
              {Number(chRoom.crmType) !== 1 && (
                <span
                  className="rounded-pill bg-light d-inline-block p-1 mr-1"
                  style={{ fontSize: "0.6em" }}
                >
                  <i className="fa-solid fa-user-group" />{" "}
                  {chRoom.chatUserList.length}
                </span>
              )}
              {loginInfo.selfChatRoomKey === chRoom.crmMainKey && (
                <span
                  className="d-inline-flex flex-row justify-content-center align-items-center bg-secondary text-white text-center rounded-circle mr-1"
                  style={{
                    width: 16,
                    height: 16,
                    fontSize: "0.8em",
                  }}
                >
                  나
                </span>
              )}
              {chRoom.chatRoomTitle}
            </div>

            <div className="position-relative d-inline-block">
              {chatUser.unreadCount > 0 && (
                <div
                  className="d-inline-block rounded-circle position-absolute d-flex flex-row justify-content-center align-items-center text-white font-weight-bold"
                  style={{
                    width: 10,
                    height: 10,
                    left: "calc(100% + 5px)",
                    top: "50%",
                    marginTop: -5,
                    background: "rgb(59, 68, 172)",
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="cursor_pointer p-2 px-3"
          onClick={() => {
            delActiveChRoom(chRoom);
          }}
        >
          &times;
        </div>
      </div>
    </li>
  );
});
