import React, { useEffect, useRef, useState } from "react";

import Select from "react-dropdown-select";
import axios from "axios";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import Utilities from "../../../../lib/Utilities";
import {
  BCompSelectOption,
  LocationSeaSelectOption,
} from "../../../../lib/CommonUI";
import MMSI from "../../schedule/mmsiList";

import ScheduleLogiWorkFCLTable from "./ScheduleLogiWorkFCLTable";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();
const mmsi = new MMSI();

const _mClassName = "ScheduleLogiWorkFCL";
const shUUID = util.makeUUIDv4();
let schedule_list = [];
let rowsPerPage = 10;
let page = 0;
let pageSize = 0;
let totalCount = 0;
let totalPageCount = 0;
let filters = {
  sm_FCL_LCL: "FCL",
  sm_DOC_CLOSE: util.todayDate("YYYY-MM-DD"),
  sm_POL_CODE: "",
  sm_POD_CODE: "",
  sm_PROMO_YN: "",
  ORIGIN: "",
  DEST: "",
  SEARCH_COMP: "",
};

let isSearch = false;

export default function ScheduleLogiWorkFCL({
  expImp,
  isPromo,
  setIsPromo,
  login_info,
  portList,
  bCompList,
  openCostPopup,
}) {
  const bComp_list = bCompList.filter((item) => item.blCompType === "F");
  const [isScheduleLoad, setIsScheduleLoad] = useState(true);
  const [selectedComp, setSelectedComp] = useState([]);

  const controller = {
    sm_EXP_IMP: useRef(),
    sm_POL_CODE: useRef(),
    sm_POD_CODE: useRef(),
    sm_PROMO_YN: useRef(),
    SEARCH_COMP: useRef(),
  };

  useEffect(() => {
    requestScheduleFCL();
  }, []);
  useEffect(() => {
    controller.sm_PROMO_YN.current.checked = !!isPromo;
    changedFilters({
      sm_EXP_IMP: expImp,
      sm_PROMO_YN: isPromo,
    });
  }, [expImp, isPromo]);

  const requestScheduleFCL = () => {
    setIsScheduleLoad(true);
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_select_full.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [
        {
          field: "sm_DOC_CLOSE",
          dir: "asc",
        },
      ],
      sp_DISPLAY: 1,
      ...filters,
      sm_EXP_IMP: expImp,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          schedule_list = newData["returnData"];
          pageSize = newData.pageSize;
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            schedule_list.length > 0
              ? parseInt(
                  parseInt(newData.pageSize, rowsPerPage) / rowsPerPage,
                  rowsPerPage
                ) +
                (parseInt(newData.pageSize, rowsPerPage) % rowsPerPage > 0
                  ? 1
                  : 0)
              : 0;

          setIsScheduleLoad(false);
        }
      }
    });
  };
  const changedFilters = (data) => {
    // if (isScheduleLoad) {
    //   codeList.Modal.current.alert("스케줄 조회중입니다.");
    // }
    page = 0;
    isSearch = true;
    if (data) {
      filters = {
        ...filters,
        ...data,
      };
    }

    if (data.hasOwnProperty("sm_PROMO_YN")) {
      requestScheduleFCL();
    }
  };

  const resetSearchFilter = () => {
    controller.sm_POL_CODE.current.clearAll();
    controller.sm_POD_CODE.current.clearAll();
    controller.SEARCH_COMP.current.clearAll();
    controller.sm_PROMO_YN.current.checked = false;
    page = 0;
    isSearch = false;
    changedFilters({
      sm_FCL_LCL: "FCL",
      sm_DOC_CLOSE: util.todayDate("YYYY-MM-DD"),
      sm_POL_CODE: "",
      sm_POD_CODE: "",
      sm_PROMO_YN: "",
      ORIGIN: "",
      DEST: "",
      SEARCH_COMP: "",
    });
  };

  const sendUseHistory = (type, isClick, data) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_select_log_insert.php",
      msgID: msgID,
      data: {},
      shIDX: data.sm_IDX,
      shType: data.sm_FCL_LCL,
      shDate: data.sm_DOC_CLOSE || filters.sm_DOC_CLOSE,
      shORIGIN: data.sm_POL_CODE,
      shDEST: data.sm_POD_CODE,
      shClick: isClick,
      shClickFor: type,
      shUUID: shUUID,
      shMethod: window.location.href, //발생 URL
      ...data,
    };

    if (login_info && login_info.hasOwnProperty("user_id")) {
      socketMsg["shCOMPCD"] = login_info.comp_cd;
      socketMsg["shUSERID"] = login_info.user_id;
    }

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {});
  };

  const changedPage = (val) => {
    page = val;
    document.querySelector(".freiscopplus").scrollTo(0, 0);
    requestScheduleFCL();
  };

  // 담당자 연결
  const requestEnquiry = (item) => {
    codeList.Modal.current.confirm(
      `[${item.sm_ORIGIN}-${item.sm_DEST}] 스케줄에 대해 담당자에게 문의하시겠습니까?`,
      (ret) => {
        if (ret) {
          if (!login_info) {
            codeList.Modal.current.alert("로그인이 필요한 서비스 입니다.");
            return;
          }
          // todo 담당자 연결 일시정지 추후 재확인 필요
          localStorage.setItem("enquiryManager", "Z0001|hskim@gbts.co.kr");
          window.open("/LogiTalk", "LogiTalk", "_blank");
        }
      }
    );
  };

  //선박추적 팝업 열기
  const handleOpenOceanLookV3 = (sm_IDX, sm_VESSEL) => {
    if (!login_info) {
      codeList.Modal.current.alert("로그인 후 이용 가능한 서비스입니다.");
      return;
    }
    let mmsiNum = mmsi.getMMSI(sm_VESSEL);

    if (mmsiNum !== "") {
      let oceanlookWebToken =
        "fc7132f8909b60db6fb4f762e250e3807bc68f6459a293545e8fdae813441cab";
      let url =
        "https://www.oceanlook.net/v3/popup?token=" +
        oceanlookWebToken +
        "&mmsi=" +
        mmsiNum;

      socket.fireLocalEventListener(
        MsgIDList.EVENT_CHANGED_LOGIWORK_OCEAN_LOOK_URL,
        _mClassName,
        url,
        () => {}
      );
    } else {
      codeList.Modal.current.alert("추적 가능한 선박이 아닙니다.");
      return false;
    }
    //}
  };
  return (
    <div className="h-100">
      {" "}
      <div className="border border-3 border-freiscop mb-2 bg-white">
        <div className="row m-0 p-0">
          <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
            <p className="font-size-09 font-weight-bold">서류마감일</p>
            <input
              type="date"
              className="w-100 border-top-0 border-left-0 border-right-0 border-bottom py-1 bg-white"
              min={util.todayDate("YYYY-MM-DD")}
              defaultValue={filters.sm_DOC_CLOSE}
              onChange={(e) => {
                changedFilters({ sm_DOC_CLOSE: e.target.value });
              }}
            />
          </div>
          <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
            <p className="font-size-09 font-weight-bold">출발지</p>
            <Select
              className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
              ref={controller.sm_POL_CODE}
              options={portList}
              placeholder="출발지를 선택해주세요"
              onChange={(val) => {
                let data = {
                  ORIGIN: val.length > 0 ? val[0]["CityName"] : "",
                  sm_POL_CODE: val.length > 0 ? val[0]["CustomCode"] : "",
                };
                changedFilters(data);
              }}
              {...LocationSeaSelectOption}
            />
          </div>
          <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
            <p className="font-size-09 font-weight-bold">도착지</p>
            <Select
              className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
              ref={controller.sm_POD_CODE}
              options={portList}
              placeholder="도착지를 선택해주세요"
              onChange={(val) => {
                let data = {
                  DEST: val.length > 0 ? val[0]["CityName"] : "",
                  sm_POD_CODE: val.length > 0 ? val[0]["CustomCode"] : "",
                };
                changedFilters(data);
              }}
              {...LocationSeaSelectOption}
            />
          </div>
        </div>

        <div className="row m-0 p-0">
          <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
            <p className="font-size-09 font-weight-bold">선사 선택</p>
            <Select
              ref={controller.SEARCH_COMP}
              placeholder="-선사 선택-"
              className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
              values={selectedComp}
              options={bComp_list}
              onChange={(value) => {
                if (value.length < 1) {
                  changedFilters({ SEARCH_COMP: "" });
                  return;
                }

                setSelectedComp(value);

                let compKeys = [];
                for (let i = 0; i < value.length; i++) {
                  let comp = value[i];
                  compKeys.push(comp.blFWDNO);
                }

                compKeys = compKeys.join("|");
                changedFilters({ SEARCH_COMP: compKeys });
              }}
              contentRenderer={({ props, state, methods }) => {
                let str = "";
                if (state.values.length > 0) {
                  str = `+ ${state.values.length} 업체`;
                } else {
                  str = props.placeholder;
                }
                return <div>{str}</div>;
              }}
              onClearAll={() => {
                setSelectedComp([]);
              }}
              {...BCompSelectOption}
            />
          </div>
          <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
            <p> </p>
            <div className="d-flex flex-row">
              <select
                className="d-inline-block border-top-0 border-left-0 border-right-0 border-bottom bg-white py-1 mr-3"
                ref={controller.sm_EXP_IMP}
                onChange={(e) => {
                  changedFilters({
                    sm_EXP_IMP: e.target.value,
                  });
                }}
                defaultValue={expImp}
              >
                <option value="">- 수출입 선택 -</option>
                <option value="EXP">수출</option>
                <option value="IMP">수입</option>
              </select>
              <label className="container_check m-0 mb-2 mb-lg-0 mr-lg-3 p-0">
                <input
                  type="checkbox"
                  className="position-static"
                  defaultChecked={filters.sm_PROMO_YN === "Y"}
                  ref={controller.sm_PROMO_YN}
                  onChange={(e) => {
                    let value = e.target.checked ? "Y" : "";
                    changedFilters({
                      sm_PROMO_YN: value,
                    });
                    setIsPromo(value);
                  }}
                />
                <span className="checkmark" />
                <span className="ml-3">프로모션 필터</span>
              </label>
            </div>
          </div>
          {selectedComp.length > 0 && (
            <div className="col-lg-12 p-0 m-0">
              <div className="d-flex flex-row flex-wrap mx-2 mb-3 mb-lg-2 bg-light">
                {selectedComp.map((item) => {
                  return (
                    <div key={item.blIDX}>
                      <div
                        role="option"
                        aria-selected="false"
                        tabIndex="-1"
                        className="react-dropdown-select-item css-148o527-ItemComponent evc32pp0 cursor_pointer_bg_light pr-2 px-lg-2 py-1 d-flex flex-row align-items-center"
                        onClick={() => {
                          let _selectedComp = [...selectedComp];
                          let delIDX = _selectedComp.findIndex(
                            (data) => data.blIDX === item.blIDX
                          );
                          if (delIDX > -1) {
                            _selectedComp.splice(delIDX, 1);
                          }
                          setSelectedComp(_selectedComp);

                          // $comp_select.current.removeItem(item);
                          // methods.addItem(item);
                        }}
                      >
                        <span className="h6 m-0 mr-2 text-danger">&times;</span>
                        {item.blLOGO && (
                          <div
                            className="d-inline-block mr-2"
                            style={{
                              width: "1rem",
                              height: "1rem",
                              background: `url(${item.blLOGO}) 50% 50% / contain no-repeat`,
                            }}
                          />
                        )}
                        <p className="d-inline-block">
                          <b>{item.blCompNM_KO}</b>{" "}
                          <span className="font-size-08">
                            [{item.blCompNM}]
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div
            className={`col-12 ${
              selectedComp.length > 0 ? "pb-2 pb-lg-3" : "col-lg-4 p-3 py-lg-2"
            }  m-0 px-2  d-flex flex-row justify-content-end align-items-end`}
          >
            <button
              className="btn_1 px-4 rounded-0 mr-2"
              onClick={requestScheduleFCL}
            >
              검색
            </button>
            <button
              className={"btn_1 bg-secondary border-secondary rounded-0"}
              onClick={resetSearchFilter}
            >
              검색조건 초기화
            </button>
          </div>
        </div>
      </div>
      <p className="h6 my-1 ">
        총{" "}
        <span className="text-info font-weight-bold">
          {util.addCommas(pageSize)}건
        </span>
        의 스케줄이 검색되었습니다
      </p>
      {isScheduleLoad ? (
        <div className="bg-light rounded p-5 h6 text-center">
          <div className="d-flex justify-content-center">
            <div className="spinner-grow text-secondary" role="status" />
          </div>
        </div>
      ) : schedule_list.length > 0 ? (
        <ScheduleLogiWorkFCLTable
          login_info={login_info}
          schedule_list={schedule_list}
          page={page}
          totalPageCount={totalPageCount}
          changedPage={changedPage}
          openCostPopup={openCostPopup}
          sendUseHistory={sendUseHistory}
          requestEnquiry={requestEnquiry}
          handleOpenOceanLookV3={handleOpenOceanLookV3}
        />
      ) : (
        <p className="h5 font-weight-bold bg-light text-secondary p-5 rounded text-center ">
          - 검색조건에 맞는 스케쥴이 존재하지 않습니다 -
        </p>
      )}
    </div>
  );
}
